import TutorialExampleLevel3 from "./TutorialExampleLevel3";
import styles from "./TutorialExamplesContainer1.module.css";
import { useTutorialsContext } from "../hooks/useTutorialsContext.js";
import { useState, useCallback } from "react";
import { TutorialsContext } from '../context/TutorialsContext';
import { useAuthContext } from "../hooks/useAuthContext";
const TutorialExamplesContainer1 = ({ tutorial }) => {
  const { tutorials, dispatch } = useTutorialsContext();
  const [userOption, setUserOption] = useState([]); // Initialize as an array

  const user = useAuthContext();

  // Assuming you get the selected programming language from somewhere (e.g., a state)
  const selectedLanguage = "Java"; // Replace with the actual selected language

  const filteredLevels = tutorial.level.filter((level) => level.progLang === selectedLanguage);

  const fetchEnrolledTutorialsPopUp = async (dispatch, user) => {
    try {
      const response = await fetch(`/api/tutorials/enrolled`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user.user.token}`,
          'Content-Type': 'application/json'
        },
      });

      const json = await response.json();

      return json;

    } catch (error) {
      console.error('Error fetching enrolled tutorials:', error);

    }
  };

  const checkforID = async (user, tutorialId) => {
    try {
      const langs = [];
      const response = await fetchEnrolledTutorialsPopUp(dispatch, user);
      const options = ["C++", "Java", "Python"];

      const enrolledTutorials = response.enrolledTutorials;

      for (var tutorial of enrolledTutorials) {
        if (tutorial.tutId._id === tutorialId) {
          langs.push(tutorial.progLang);
        }
      }

      for (const lang of langs) {
        if (lang === "C++" || lang === "Java" || lang === "Python") {
          var index = options.indexOf(lang);
          if (index !== -1) {
            options.splice(index, 1);
          }
        }
      }

     

      setUserOption(langs);
      return langs;
    } catch (error) {
      console.error("Error fetching enrolled tutorials:", error);
      return null; // Return null instead of JSX element
    }
  };

  const vals = checkforID(user, tutorial._id);
  const options = userOption;

  return (
    <div className={styles.tutorialexamples}>
      <div className={styles.tutorialExamples}>Tutorial Examples</div>


      {filteredLevels.map((level, index) => (
        <TutorialExampleLevel3
          key={index}
          tutorial={tutorial}
          tutorialId={tutorial._id}
          lessonTitle={`Level ${level.levelNumber}`}
          lessonDescription={level.codeData[0].code[0]}
          property1DefaultWidth="100%"
          property1DefaultHeight="23.19%"
          property1DefaultPosition="absolute"
          property1DefaultTop={`${(index % 4) * 100}px`}
          property1DefaultRight="0%"
          property1DefaultBottom="54.82%"
          property1DefaultLeft="0%"
          levelClicked={`${level.levelNumber}`}
          options={userOption} // Pass options as a prop
          // Add other properties as needed based on your data structure
          // ...
        />
      ))}
    </div>
  );
};

export default TutorialExamplesContainer1;
