import React, { useState, useEffect, useRef } from "react";
import AceEditor from "react-ace";
import ace from "ace-builds";


import "brace/mode/python";
import "brace/mode/java";
import "brace/mode/javascript";
import "brace/theme/dracula";
import styles from "./OutputContainer.module.css";
import { message } from "antd";
import { useAuthContext } from "../hooks/useAuthContext";
import { useProjectsContext } from "../hooks/useProjectsContext.js";
import { useTutorialsContext } from "../context/TutorialsContext";
import { useCodeFilesContext } from "../hooks/useCodeFilesContext";
import { getLinesOfCode } from "./parentClassData.js";

// Initialize Ace Editor
ace.config.set("basePath", "/path/to/ace");
const OutputContainer = ({ customOutputContainer, project, tutorial, levelClicked, language, undoButton, ProgramLang, linesHighlighted }) => {
  const user = useAuthContext();
  const { dispatch: dispatchTutorial, isObjectifyingTutorial } = useTutorialsContext();
  const { dispatch: dispatchProject, isObjectifyingProject } = useProjectsContext();
  const { dispatch: dispatchCodeFiles } = useCodeFilesContext();
  const prevSelectedFile = useRef(null);
  const editorRef = useRef(null);

  const displayMessage = (type, content, duration = 3) => {
    // Destroy any existing messages
    message.destroy();
    // Display the new message based on the type
    switch (type) {
      case "info":
        message.info(content, duration);
        break;
      case "success":
        message.success(content, duration);
        break;
      case "warning":
        message.warning(content, duration);
        break;
      case "error":
        message.error(content, duration);
        break;
      default:
        message.info(content, duration);
        break;
    }
  };
  const { state: { codeFiles: contextCodeFiles, selectedFile: selectedFile } } = useCodeFilesContext();
  const [code, setcode] = useState("");
  const [lineMarkers, setLineMarkers] = useState([]);


  const [currentLevel, setCurrentLevel] = useState(null); // current tutorial level/ language array with everything

  // Get the lines of code array
  const linesOfCode = getLinesOfCode();



  // Filter tutorials based on level and language
  // const filteredTutorials = tutorial.filter(tutorial => tutorial.levelNumber=== levelClicked && tutorial.progLang=== language);

  // Iterate over the properties of the tutorial object
  for (const key in tutorial) {
    if (tutorial.hasOwnProperty(key)) {
      const currentTutorial = tutorial[key];
      // Check if the current tutorial matches the criteria
      if (
        currentTutorial.levelNumber === levelClicked &&
        currentTutorial.progLang === language
      ) {
        // Access other properties of the tutorial as needed
      }
    }
  }


  const lineArray = [];
  const generateMarkers = (linesOfCode) => {
    const newMarkers = linesOfCode.flatMap((linesOfCode) => {
      const startRow = parseInt(
        linesOfCode.substring(1, linesOfCode.indexOf(" - ")),
        10
      );
      const endRow = parseInt(
        linesOfCode.substring(linesOfCode.indexOf(" - ") + 3, linesOfCode - 1),
        10
      );
      lineArray.push({ startRow, endRow: endRow }); // Push an object containing lineNumber and endRow
      lineArray.push({ startRow, endRow: endRow }); // Push another object for the second range
      return [
        {
          startRow: startRow,
          endRow: endRow,
          className: `highlighted`, // Use class name to determine highlighting style
          type: "background",
        },
      ];
    });
    setLineMarkers(newMarkers);
  };


  //const [selectedFile, setSelectedFile] = useState(null); // the file whose code is currently open for both
  const [openFiles, setOpenFiles] = useState([]); // all the tabs that are open for tutorial and projects
  const [currentUndoStateIndex, setCurrentUndoStateIndex] = useState(0); // State to store the undo state index



  const handleUndo = async () => {
    if (!selectedFile) {
      dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: selectedFile });
    }

    const length = project?.project?.codeStates?.length;
    // Check if there are previous states to undo to
    let headers = { "Content-Type": "application/json" };

    if (
      project.project._id !== "65d57921b81b7f5c349e0705" &&
      user.user.userEmail
    ) {
      headers = { "Content-Type": "application/json" };
    }
    const fetchResponse = await fetch(`/api/projects/${project.project._id}`, {
      headers: headers,
    });
    const json = await fetchResponse.json();
    if (fetchResponse.ok && currentUndoStateIndex < length && undoButton) {
      setCurrentUndoStateIndex((prevIndex) => prevIndex + 1);

      // Retrieve code data from the previous state
      const codeFilesFromProject =
        project?.project?.codeStates[length - currentUndoStateIndex - 1]
          ?.codeData;
      // Set code files to the code data from the previous state
      dispatchCodeFiles({ type: "UPDATE_CODE", payload: codeFilesFromProject });
      // onCodeFilesUpdate(codeFilesFromProject);
      setcode(
        contextCodeFiles[selectedFile]?.code.join("\n") ||
        "The creation of this file has been undone"
      );
      dispatchProject({ type: "GET_PROJECT", payload: json });
      // onCodeFilesUpdate(codeFilesFromProject)

      return null;
    } else {
      displayMessage("info", "Unable to Undo. There are no actions to revert");

      return null;
    }
  };

  // Function to extract lines of code from codeData for porjects
  function extractLinesOfCode(projectData) {
    const linesOfCode = [];
    projectData.project.codeStates.forEach((codeState) => {
      codeState.relationships.forEach((relationships) => {
        linesOfCode.push(relationships.linesOfCode);
      });
    });
    return linesOfCode;
  }


  function extractLinesOfCodeTut(tutorial, levelClicked, language) {
    const linesOfCode = [];
    tutorial.level.forEach(levelObj => {
      // Check if the current level object matches the clicked level and language
      levelClicked = parseInt(levelClicked)
      if (levelObj.levelNumber === levelClicked && levelObj.progLang === language) {
        // If it matches, iterate over the 'relationships' array of the current level object
        levelObj.relationships.forEach((relationship) => {
          // Add lines of code to the 'linesOfCode' array
          linesOfCode.push(relationship.linesOfCode);
        });
      }
    });
    return linesOfCode;
  }

  // const linesOfCodeArray = extractLinesOfCodeTut(tutorial, levelClicked, language);


  useEffect(() => {
    if (project) {
      const length = project?.project?.codeStates?.length;
      if (undoButton) {
        handleUndo();
      }
      else if (!undoButton && currentUndoStateIndex === 0) { // if im not in the middle of an undo but i dont think its ever going here
        setcode(contextCodeFiles[selectedFile]?.code?.join("\n"));
      }
    }

    // else if (tutorial) {
    //   setcode(contextCodeFiles[selectedFile]?.code.join("\n"));

    // }


  }, [project, contextCodeFiles, undoButton]); // removed tutorials 


  useEffect(() => {

    // Check if contextCodeFiles is available and selectedFile is not null or undefined
    if (
      contextCodeFiles &&
      selectedFile !== null &&
      selectedFile !== undefined
    ) {
      // If there are no files open by default and selectedFile is not null or undefined
      if (openFiles.length === 0) {
        setOpenFiles([0]);
      } else if (!openFiles.includes(selectedFile)) {
        // If selectedFile is not in openFiles, add it to openFiles
        setOpenFiles((prevFileNames) => [...prevFileNames, selectedFile]);
      }

      // Update the selected file in the code files context
      dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: selectedFile });

      // Set code to the code of the selected file or to an empty string if it's not available
      setcode(contextCodeFiles[selectedFile]?.code.join("\n") || "");
    }

    // If selectedFile is null, set it to 0 in the code files context
    if (selectedFile === null && prevSelectedFile.current !== null) {
      dispatchCodeFiles({
        type: "UPDATE_FILE_SELECTED",
        payload: prevSelectedFile.current,
      });
    }


    prevSelectedFile.current = selectedFile;
    dispatchCodeFiles({ type: "ACE_EDITOR_REF", payload: editorRef });

  }, [contextCodeFiles, selectedFile, project]);

  useEffect(() => {
    if (tutorial) {
      dispatchTutorial({
        type: "SET_OBJECTIFY_STATE_TUTORIAL",
        payload: false,
      }); // or false
    }
    // if(project){
    //   dispatchProject({ type: 'SET_OBJECTIFY_STATE_PROJECT', payload: true }); // or false
    // }
  }, [tutorial, project])


  useEffect(() => {


    // dispatchCodeFiles({type:'UPDATE_FILE_SELECTED', payload:selectedFile });
    //linesHighlighted
    let lines = linesHighlighted;
    if (lines?.length == 0) {

      lines = project?.project?.codeStates[project?.project?.codeStates.length - 1]?.classes[0]?.linesOfCode;

    }
    if (lines?.length > 0) {

      const markerStyle = `
.highlighted {
  background-color: #565869;
  position: absolute;
}
`;

      // Apply marker style to Ace Editor
      const aceEditor = ace.edit("editor");
      if (project?.project?.progLang === 'python') {
        aceEditor.getSession().setMode("ace/mode/python")
      }
      else {
        aceEditor.getSession().setMode("ace/mode/java");
      }
      aceEditor.setTheme("ace/theme/dracula");



      // Clear existing markers
      const session = aceEditor.getSession();
      session.clearAnnotations(); // Clear existing annotations
      const markers = session.getMarkers();
      for (const id in markers) {
        session.removeMarker(id);
      }


      // Extract line numbers from the string
      const lineNumbersRegex = /\[(\d+) - (\d+)\]/; // Regex to match the line numbers in the format "[start - end]  [1-7] , 1 , 3"
      const match = lines.match(lineNumbersRegex);

      if (match) {
        const startLine = parseInt(match[1]); // Extract the first number as the start line
        const endLine = parseInt(match[2]); // Extract the second number as the end line

        const range = new ace.Range(startLine - 1, 0, endLine, 0); // Ace Editor uses zero-based line indexing
        const markerId = session.addMarker(range, "highlighted", "line", false); // Add a marker for the line

      }

      // Apply marker style to Ace Editor
      const style = document.createElement("style");
      style.appendChild(document.createTextNode(markerStyle));
      document.head.appendChild(style);
    }
    const aceEditor = ace.edit("editor");

    // Function to handle click event on Ace Editor
    const handleClick = () => {
      // Clear existing markers
      const session = aceEditor.getSession();
      session.clearAnnotations(); // Clear existing annotations
      const markers = session.getMarkers();
      for (const id in markers) {
        session.removeMarker(id);
      }
    };

    // Add click event listener to the Ace Editor
    aceEditor.on("click", handleClick);

    // Cleanup function to remove the event listener when component unmounts
    return () => {
      aceEditor.off("click", handleClick);
    };
  }, [linesHighlighted])

  //upp file part
  const handleFileSelected = (index) => {
    if (index >= 0 && index < contextCodeFiles.length) {
      if (selectedFile !== undefined && selectedFile !== null) {
        dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: index });
      }
    }
  };

  const handleCloseFile = (index) => {
    dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: openFiles[0] });
    // the if condiiton shouldnt be index but the stuff inside needs to be index
    if (openFiles.includes(index) && openFiles.length >= 1) {

      const updatedOpenFiles = openFiles.filter((fileName) => fileName !== index);
      setOpenFiles(updatedOpenFiles);
      const newCode = contextCodeFiles[openFiles[0]]?.code?.join('\n');


      setcode(newCode);
    }
    else {
      setcode("Open a file or click + to add a new fileeee");
    }
  };

  const handleObjectify = async () => {


    setCurrentUndoStateIndex(0);
    let hasError = false;
    if (project) {

      // Show loading message while updating code data
      const loadingMessage = message.loading("Generating Diagram...", 0);

      const updatedCodeFiles = contextCodeFiles.map((file) => ({
        fileName: file.fileName,
        code: file.code,
      }));

      // Create codeData directly with the "codeData" key
      const codeData = { codeData: updatedCodeFiles };
      // Update the project's codeStates
      dispatchProject({ type: "UPDATE_PROJECT", payload: codeData });
      dispatchProject({ type: "SET_OBJECTIFY_STATE_PROJECT", payload: false });

      try {
        let headers = { "Content-Type": "application/json" };

        const response = await fetch(`/api/projects/${project.project._id}`, {
          method: "PATCH",
          headers: headers,
          body: JSON.stringify(codeData),
        });

        if (response.ok) {
          headers = { "Content-Type": "application/json" };

          if (
            project.project._id !== "65d57921b81b7f5c349e0705" &&
            user.user.userEmail
          ) {
            headers = { "Content-Type": "application/json" };
          }
          dispatchProject({ type: "SET_OBJECTIFY_STATE_PROJECT", payload: true });

          const fetchResponse = await fetch(
            `/api/projects/${project.project._id}`,
            {
              headers: headers,
            }
          );
          const json = await fetchResponse.json();
          if (fetchResponse.ok) {
            dispatchProject({ type: "GET_PROJECT", payload: json });
            // here logic to fetch the last code state again/
            //what file are we on
          }

          // dispatchCodeFiles({type:'UPDATE_FILE_SELECTED', payload:selectedFile });
          //linesHighlighted
          const linesOfCodeArray = linesHighlighted;


          const markerStyle = `
              .highlighted {
              background-color: #565869;
              position: absolute;
              }
              `;

          // Apply marker style to Ace Editor
          const aceEditor = ace.edit("editor");
          if (project?.project?.progLang === 'python') {
            aceEditor.getSession().setMode("ace/mode/python")
          }
          else {
            aceEditor.getSession().setMode("ace/mode/java");
          }
          aceEditor.setTheme("ace/theme/dracula");



          // Clear existing markers
          const session = aceEditor.getSession();
          session.clearAnnotations(); // Clear existing annotations
          const markers = session.getMarkers();
          for (const id in markers) {
            session.removeMarker(id);
          }


          // Extract line numbers from the string
          const lineNumbersRegex = /\[(\d+) - (\d+)\]/; // Regex to match the line numbers in the format "[start - end]  [1-7] , 1 , 3"
          const match = linesOfCodeArray.match(lineNumbersRegex);

          if (match) {
            const startLine = parseInt(match[1]); // Extract the first number as the start line
            const endLine = parseInt(match[2]); // Extract the second number as the end line

            const range = new ace.Range(startLine - 1, 0, endLine - 1, 0); // Ace Editor uses zero-based line indexing
            const markerId = session.addMarker(range, "highlighted", "line", false); // Add a marker for the line

          }

          // Apply marker style to Ace Editor
          const style = document.createElement("style");
          style.appendChild(document.createTextNode(markerStyle));
          document.head.appendChild(style);

        } else {
          console.error("Error updating data:", response.statusText);
          const errorMessage = await response.json(); // Extract error message from response body
          message.error(errorMessage.error);
          hasError = true;
        }
      } catch (error) {
        console.error("Error updating data:", error);
      } finally {
        // Hide loading message when data processing is complete
        loadingMessage();

        if (!hasError) {
          message.success("Your code has been visualized successfully!");
        }
      }
    }


    else if (tutorial) {

      // Output the array of lines of code


      dispatchTutorial({ type: "SET_OBJECTIFY_STATE_TUTORIAL", payload: true }); // or false
    }
  };

  const handleCodeChange = (newCode) => {
    if (project) {
      const updatedCodeFiles = contextCodeFiles.map((file) => {
        if (file?.fileName === contextCodeFiles[selectedFile]?.fileName) {
          return {
            fileName: file.fileName,
            code: newCode.split("\n"),
          };
        }
        return file;
      });
      // onCodeFilesUpdate(updatedCodeFiles);
      if (!selectedFile) {
        dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: selectedFile });
      }

      dispatchCodeFiles({ type: 'UPDATE_CODE', payload: updatedCodeFiles });

    }

  };




  return (
    <div
      className={`${styles.defaultOutputContainer} ${customOutputContainer}`}
    >
      {/* <button onClick={() => handleDeleteElement("class Employee")}>
        Delete Element
      </button> */}
      {/* <div className={styles.topbarFiles}> */}
      <button className={styles.explorebutton} onClick={handleObjectify}>
        <div className={styles.objectify}>Objectify</div>
        <img
          className={styles.icPlayArrow48pxIcon}
          alt=""
          src="/ic-play-arrow-48px.svg"
        />
      </button>

      <div className={styles.fileContainer}>
        {openFiles.length === 0 && (
          <div
            key={-1}
            className={`${styles.file}`}
            style={{ padding: "0px" }}
          ></div>
        )}

        {openFiles.map((fileName, index) => (
          <div
            key={index}
            className={`${styles.file} ${selectedFile === openFiles[index] ? styles.selected : ""
              }`}
            onClick={() => handleFileSelected(openFiles[index])}
          >
            <div className={styles.icon}>
              <img className={styles.vectorIcon} alt="" />
              <img
                className={styles.vectorIcon1}
                alt=""
                src="/vector5@2x.png"
              />
              <b className={styles.js}>J</b>
            </div>
            <div className={styles.mainjs}>
              {contextCodeFiles[openFiles[index]]?.fileName}
            </div>
            <img
              className={styles.pagecrossIcon}
              alt=""
              src="/pagecross1.svg"
              onClick={() => handleCloseFile(openFiles[index])}
            />
          </div>
        ))}
      </div>

      <div className={styles.code}>
        {project && project?.project?._id !== "65d57921b81b7f5c349e0705" ? (
          <AceEditor
            padding=""
            right="400px"
            left="500px"
            height="827px"
            width="548px"
            //  mode="java"
            mode={ProgramLang}
            theme="dracula"
            name="editor"
            fontSize={14}
            markers={lineMarkers}
            editorProps={{ $blockScrolling: true }}
            value={code}
            ref={editorRef}
            onChange={(newCode) => handleCodeChange(newCode)}
          />
        ) : (project && project?.project?._id === "65d57921b81b7f5c349e0705" ? (
          <AceEditor
            padding=""
            right="400px"
            left="500px"
            height="827px"
            width="548px"
            //  mode="java"
            mode={ProgramLang}
            theme="dracula"
            name="editor"
            fontSize={14}
            editorProps={{ $blockScrolling: true }}
            value={code}
            readOnly={true}
            ref={editorRef}
            onCursorChange={() =>
              displayMessage(
                "info",
                "Click Objectify to get started. To edit the code or use your own code, please log in."
              )
            }
          />
        ) : (
          <AceEditor
            padding=""
            right="400px"
            left="500px"
            height="827px"
            width="548px"
            //  mode="java"
            mode={ProgramLang}
            theme="dracula"
            name="editor"
            fontSize={14}
            editorProps={{ $blockScrolling: true }}
            value={code}
            readOnly={true}
            ref={editorRef}
          />
        ))}
      </div>
      <div className={styles.frameParent}>
        <div className={styles.frameG65pxroup}>
          <div className={styles.outputWrapper}>
            <div className={styles.mainjs}>Output</div>
          </div>
        </div>
        <div className={styles.frameChild} />
      </div>
    </div>
  );
};

export default OutputContainer;
