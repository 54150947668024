import { useEffect, useState, useCallback, useRef } from "react";
import CodeEditorPopUp from "./CodeEditorPopUp";
import PortalPopup from "./PortalPopup";
import styles from "./FileNavigationContainer.module.css";
import PropTypes from "prop-types";

import { useProjectsContext } from "../hooks/useProjectsContext";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCodeFilesContext } from "../hooks/useCodeFilesContext";
import { message } from "antd";


const FileNavigationContainer = ({ customClassName, project, tutorial, levelClicked, language }) => {
  const [isCodeEditorPopUpOpen, setCodeEditorPopUpOpen] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const selectedFileLocal = useRef(null);
  const { state: { codeFiles: contextCodeFiles, selectedFile }, dispatch: dispatchCodeFiles } = useCodeFilesContext();

  const { dispatch } = useProjectsContext();
  const user = useAuthContext();
  const openCodeEditorPopUp = useCallback(() => {
    if (project) {
      setCodeEditorPopUpOpen(true);
    }
  }, [project]);

  const closeCodeEditorPopUp = useCallback((fileName) => {
    if (project) {
      setCodeEditorPopUpOpen(false);
    }
  }, []);

  useEffect(() => {
    // Check if contextCodeFiles is available and is not null or undefined
    if (contextCodeFiles && contextCodeFiles.length > 0) {
      // Update the selected file in the code files context
      dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: selectedFile });

      // Set file names based on contextCodeFiles
      setFileNames(contextCodeFiles.map((data) => data.fileName));

      // If selectedFile is not null, handle the file click
      if (selectedFile !== null) {
        handleFileClick(selectedFile);
      }

    }
  }, [tutorial, project, selectedFile, contextCodeFiles]);

  const handleFileClick = (index) => {
    if (selectedFile !== undefined && selectedFile !== null) {
      dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: index });
    }

  };

  const handleFileDelete = async (index) => {
    // Update the project data to reflect the deletion
    if (project) {
      // Clone the existing codeStates to avoid mutating the state directly
      const updatedCodeStates = [...project?.project?.codeStates];
      const length = project?.project?.codeStates?.length;
      // Remove the file from the codeData array of the latest codeState
      updatedCodeStates[length - 1].codeData.splice(index, 1);
      let headers = { 'Content-Type': 'application/json' };
      if (project.project._id !== "65d57921b81b7f5c349e0705" && user.user.userEmail) {
        headers = { 'Content-Type': 'application/json', 'Authorization': `Bearer ${user.user.token}` };
      }

      dispatch({ type: 'UPDATE_PROJECT', payload: updatedCodeStates[length - 1].codeData });
      dispatchCodeFiles({ type: 'UPDATE_CODE', payload: updatedCodeStates[length - 1].codeData });


      // Update the API/database
      const fetchUpdatedResponse = await fetch(`/api/projects/${project.project._id}`, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify({ codeData: updatedCodeStates[length - 1].codeData }),
      });

      if (fetchUpdatedResponse.ok) {
        headers = { 'Content-Type': 'application/json' };

        if (project.project._id !== "65d57921b81b7f5c349e0705" && user.user.userEmail) {
          headers = { 'Content-Type': 'application/json' };
        }
        const fetchResponse = await fetch(`/api/projects/${project.project._id}`, {
          headers: headers
        });
        const json = await fetchResponse.json();
        if (fetchResponse.ok) {
          dispatch({ type: 'GET_PROJECT', payload: json });
          // here logic to fetch the last code state again/ 
          //what file are we on 
          message.success("File deleted successfully!")
        }


      } 


    }
  };


  return (
    <>
      <div className={`${styles.defaultFileNavigation} ${customClassName}`}>
        <div className={styles.browser}>
          <div className={styles.project}>
            
            {project ? (
              <div>
                <div className={styles.button} />
                <div className={styles.myoopproject}>{project?.project?.prjName}</div>
              </div>
            ) : (
              <div>
              <div className={styles.button} />
              <div className={styles.myoopproject}>{tutorial?.tutName+"-"+language}</div>
            </div>
            )}

          </div>
          <div className={styles.fileContainer}>
            {fileNames.map((fileName, index) => (
              <div
                key={index}
                className={`${styles.file} ${selectedFile === index ? styles.selected : ''}`}
                onClick={() => {
                  handleFileClick(index);
                  // Pass the selected file name to the parent component
                }}
              >
                <div
                  className={styles.deleteIcon}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent file click event from triggering
                    handleFileDelete(index);
                  }}
                >
                  {/* <img src="/delete.png" alt="Delete" /> */}
                  <span
                    style={{
                      cursor: 'pointer',
                      color: 'black',
                      transition: 'color 0.1s', // Smooth transition effect
                      width: '0px',
                      left: '10px',
                      position: 'relative',
                      display: 'flex',

                    }}
                    onMouseEnter={(e) => { e.target.style.color = 'red'; }} // Change color on hover
                    onMouseLeave={(e) => { e.target.style.color = 'black'; }} // Restore initial color
                  >
                    &#128465;&nbsp;
                  </span>         
                </div>
                <div className={styles.nodeModules}>{fileName}</div>

              </div>
            ))}

          </div>
        </div>


        <div className={styles.fileAdd}>File</div>
        <button
          className={styles.image14}
          id="buttonForAddingFile"
          onClick={openCodeEditorPopUp}
        />
      </div>

      {isCodeEditorPopUpOpen && (
        <PortalPopup
              overlayColor="rgba(0 0 0 / 67%)"
          placement="Centered"
          onOutsideClick={() => closeCodeEditorPopUp()}
        >
          <CodeEditorPopUp
            projId={project?.project?.projId}
            onClose={() => closeCodeEditorPopUp()}
            onSave={(fileName) => closeCodeEditorPopUp(fileName)}
          />
        </PortalPopup>
      )}
    </>
  );
};




export default FileNavigationContainer;