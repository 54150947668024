import { createContext, useReducer, useEffect, useState } from 'react';
import styles from "../pages/Loading.module.css";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.payload };
    case 'LOGOUT':
      return { user: null };
    case 'GET_PROFILE':
      return {
        ...state,
        users: action.payload,
      };
    case 'UPDATE_ACCOUNT_INFO':
      return {
        ...state,
        users: { ...state.users, ...action.payload },
      };
    case 'DELETE_ACCOUNT':
      return { user: null };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, { user: null });
  const [loading, setLoading] = useState(true); // Add loading state
  const updateProfile = (updatedData) => {
    dispatch({ type: 'UPDATE_ACCOUNT_INFO', payload: updatedData });
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      dispatch({ type: 'LOGIN', payload: user });
    } 

    // Set loading to false after checking localStorage
    setLoading(false);
  }, []);


  if (loading) {
    return (
      <div className={styles.Home}>
              <div className={styles.thirdpage}>
        <div className={styles.wrapperFrame33655Parent}>
          <div className={styles.wrapperFrame33655}>
            <img
              className={styles.wrapperFrame33655Child}
              alt=""
              src="/frame-33655@2x.png"
            />
          </div>
          <div className={styles.wrapperFrame33654}>
            <img
              className={styles.wrapperFrame33654Child}
              alt=""
              src="/frame-33654@2x.png"
            />
          </div>
          <div className={styles.middleinfo1}>
            <div className={styles.ourSolution}>Loading VOOP</div>
            <div className={styles.ourTeamAims}>
             Learn OOP like never before
            </div>

          </div>
        </div>
      </div>
      </div>
    );
  }

  return (
    <AuthContext.Provider value={{ ...state, dispatch, updateProfile }}>
      {children}
    </AuthContext.Provider>
  );
};
