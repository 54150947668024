import { useMemo } from "react";
import styles from "./ProjectFrame.module.css";
import { useState, useCallback, useEffect } from "react";
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useProjectsContext } from "../hooks/useProjectsContext.js";
import DeleteProject from "../components/DeleteProject";
import PortalPopup from "../components/PortalPopup";
import React from "react";

const ProjectFrame = ({
  project,
  edited5MinAgo,
  project1,
  editMinus,
  j,
  showEditMinus,
  projectFrameWidth,
  projectFramePosition,
  projectFrameTop,
  projectFrameLeft,
  onclick
}) => {
  const [isDeleteProjectOpen, setDeleteProjectOpen] = useState(false);

  const openDeleteProject = useCallback(() => {
    setDeleteProjectOpen(true);
  }, []);

  const closeDeleteProject = useCallback(() => {
    setDeleteProjectOpen(false);
  }, []);

  // Function to generate random color
  const generateRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const colors = ['#7E8F83', '#5C83BD', '#B47FA9', '#9F8AB9', '#7C86AB'];

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }


 const [randomGradient, setRandomGradient] = useState(getRandomGradient());

  // Function to get a random linear gradient from the provided array
  function getRandomGradient() {
    const gradients = [
      'linear-gradient(92.09deg, rgb(65 128 204) 36.98%, rgb(5 72 155))',
      'linear-gradient(92.09deg, rgb(160 91 53) 36.98%, rgb(190, 81, 65))',
      'linear-gradient(96.5deg, rgb(165 87 143) 36.98%, rgb(122 55 103))',
      'linear-gradient(101.29deg, rgb(101 86 162) 36.98%, rgb(119, 90, 180))',
      'linear-gradient(96.5deg, rgb(192 153 77) 36.98%, rgb(175 120 10))',
      'linear-gradient(92.09deg, rgb(107 138 188) 36.98%, rgb(67 95 139))',
      'linear-gradient(96.5deg, rgb(90 148 142) 36.98%, rgb(64 141 133))',
      'linear-gradient(92.09deg, rgb(59 136 136) 36.98%, rgb(18, 117, 117))'
    ];
    const randomIndex = Math.floor(Math.random() * gradients.length);
    return gradients[randomIndex];
  }
  
  // Update the random gradient when the component mounts
  useEffect(() => {
    setRandomGradient(getRandomGradient());
  }, []);

  // Memoize the random color for each project
  const projectBoxBackground = useMemo(() => randomGradient, []);

  return (
    <>
      <div className={styles.projectframe} style={{ width: projectFrameWidth, position: projectFramePosition, top: projectFrameTop, left: projectFrameLeft }}>
        <div onClick={onclick}>
          <div className={styles.edited5Min}>Updated {formatDistanceToNow(new Date(edited5MinAgo), { addSuffix: true })}</div>
          <div className={styles.project1} onClick={onclick}>{project1}</div>
          <div className={styles.projectbox} style={{ background: projectBoxBackground }} onClick={onclick}></div>
        </div>

        {!showEditMinus && (
          <img
            className={styles.editMinus}
            alt=""
            src={"/edit--minus@2x.png"}
            onClick={openDeleteProject}
          />
        )}

        <div className={styles.j}>{j}</div>
      </div>

      {isDeleteProjectOpen && (
        <PortalPopup
          overlayColor="rgba(0 0 0 / 67%)"
          placement="Centered"
          onOutsideClick={closeDeleteProject}
        >
          <DeleteProject projectID={project._id} onClose={closeDeleteProject} />
        </PortalPopup>
      )}
    </>
  );
};

export default ProjectFrame;
