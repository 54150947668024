import { useState, useEffect } from "react";
import { message } from 'antd';
import TutorialStep from "./TutorialStep";
import styles from "./AnimationContainer.module.css";
import { useProjectsContext } from "../hooks/useProjectsContext.js";
import { useTutorialsContext } from "../context/TutorialsContext";
import AnimationDatabase from "./AnimationDatabase.js";
import { useAuthContext } from "../hooks/useAuthContext";
import { useCodeFilesContext } from "../hooks/useCodeFilesContext";

const AnimationContainer = ({
  AnimationContainer,
  project,
  tutorial,
  levelClicked,
  language,
  undoButton,
  updateLinesHighlighted
}) => {
  const { isObjectifyingProject } = useProjectsContext();
  const { isObjectifyingTutorial } = useTutorialsContext();
  const user = useAuthContext();
  const [codeState, setcodeState] = useState(null);
  const [currentConceptState, setCurrentConceptState] = useState(0);
  const [currentInstanceState, setCurrentInstanceState] = useState(0);
  const [currentClassState, setCurrentClassState] = useState(0);
  const [clickedLevel, setClickedLevel] = useState(null);
  const [currentStepIndex, setCurrentStepIndex] = useState(0); // Track the current step index
  const [isEditPlusClicked, setIsEditPlusClicked] = useState(false);
  const [isEditMinusClicked, setIsEditMinusClicked] = useState(false);
  const [generatedRecommendations, setGeneratedRecommendations] = useState(""); // this variable needs to be called into the green and red boxes
  const [currentRecommendation, setCurrentRecommendation] = useState(0); // this variable needs to be called into the green and red boxes
  const [linesHighlighted, setLinesHighlighted] = useState([]);

  const { state: { codeFiles, selectedFile }, dispatch: dispatchCodeFiles } = useCodeFilesContext();


  const handleUpdateLinesHighlighted = (newLinesHighlighted) => {
    // Logic to determine the new value of linesHighlighted

    // Call the updateLinesHighlighted function passed from parent component
    updateLinesHighlighted(newLinesHighlighted);
  };

  const handleEditPlusClick = () => {
    if (!isEditPlusClicked) {
      setCurrentRecommendation(0);
      setIsEditMinusClicked(false);
      setIsEditPlusClicked(true);
      getRecommendations("add");
    } else {
      setCurrentRecommendation(0);
      setIsEditPlusClicked(false);
    }
    setGeneratedRecommendations("");
  };

  const handleEditMinusClick = () => {
    if (!isEditMinusClicked) {
      setCurrentRecommendation(0);
      setIsEditPlusClicked(false);
      setIsEditMinusClicked(true);
      getRecommendations("remove");
    } else {
      setCurrentRecommendation(0);
      setIsEditMinusClicked(false);
    }
    setGeneratedRecommendations("");
  };
  useEffect(() => {
  }, [isEditPlusClicked, isEditMinusClicked]); // Pass isEditPlusClicked as a dependency

  const getRecommendations = async (change) => {
    let loadingMessage = null; // Initialize loadingMessage outside the try block
    try {

      // Display loading message
      loadingMessage = message.loading('Fetching recommendations...', 0);


      const source = (codeState?.classes || [])
        .map((classObj) => classObj.name)
        .join(", ");
      // if theres no token message.alert("Create an account to try our AI features")
      const code = codeState?.codeData?.reduce((acc, curr) => {
        // Join the code array into a single string and append to accumulator
        return acc + curr.code.join("\n") + "\n";
      }, "");

      const response = await fetch("/api/projects/getRecommendations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.user.token}`,
        },
        body: JSON.stringify({
          currentCode: code,
          sourceComponent: source,
          typeOfChange: change,
        }),
      });
      if (!response.ok) {
        loadingMessage();
        message.info("Updated Code");
        throw new Error("Failed to fetch recommendations");

      }
      const data = await response.json();

      // Close loading message when done
      loadingMessage();
      message.success("Recommendations Generated! Click Implement on any recommendation to make the changes to your code.", 5)
      const parsedRecommendations = parseRecommendationString(data);
      setGeneratedRecommendations(parsedRecommendations);


    } catch (error) {
      // Close loading message in case of error
      if (loadingMessage) {
        loadingMessage(); // Close the loading message
      }
      console.error("Error fetching recommendations:", error.message);
    }
  };

  const generateCode = async (rec) => {
    let loadingMessage = null;
    try {
      // Display loading message
      loadingMessage = message.loading('Generating code...', 0);

      const code = codeState?.codeData;

      const response = await fetch(
        `/api/projects/${project.project._id}/implementRecommendation`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${user.user.token}`,
        },
        body: JSON.stringify({ currentCode: code, recommendation: rec }),
      }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error("Failed to fetch recommendations");
      }

      // Close loading message when done
      loadingMessage();
      message.success("Recommendation has been implemented into your code successfully!", 5)
      dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: null });
      dispatchCodeFiles({ type: 'UPDATE_CODE', payload: responseData?.codeData });

      // onCodeFilesUpdate(data?.codeData);

    } catch (error) {
      // Close loading message in case of error
      loadingMessage();
      message.error('Error generating code');
      console.error("Error fetching recommendations:", error.message);
    }
  };

  function parseRecommendationString(str) {
    // Split the string by newline characters
    const lines = str.split("\n");

    // Initialize an array to store parsed recommendations
    const recommendations = [];
    let currentRecommendation = null; // Changed initialization to null

    // Iterate through each line
    lines.forEach((line) => {
      // Trim extra spaces and remove '*' from the beginning and end of the line
      line = line.trim().replace(/\*/g, "");

      // If the line is not empty
      if (line) {
        // If the line starts with a number
        if (/^\d/.test(line)) {
          // If there's a current recommendation being processed, push it to the array
          if (currentRecommendation) {
            recommendations.push(currentRecommendation);
          }
          // Create a new recommendation object
          const recommendationNumberMatch = line.match(/^(\d+)\.\s*(.*)/);
          if (recommendationNumberMatch) {
            const recommendationNumber = parseInt(recommendationNumberMatch[1]);
            const item = recommendationNumberMatch[2].trim();
            currentRecommendation = {
              recommendationNumber,
              item,
              recommendation: "",
            };
          }
        } else if (line.includes("Recommendation:") || line.includes("Justification:")) {
          // If the line contains 'Recommendation:' or 'Justification:', treat everything after ':' accordingly
          const parts = line.split(":");
          const header = parts[0].trim();
          const content = parts.slice(1).join(":").trim();

          if (currentRecommendation) {
            if (header === "Recommendation") {
              currentRecommendation.recommendation = content;
            } else if (header === "Justification") {
              currentRecommendation.recommendation += content;
            }
          }
        } else {
          // If no recommendation is found, treat the line as part of item or recommendation
          if (currentRecommendation) {
            const parts = currentRecommendation.item.split(":");
            if (parts.length === 2) {
              currentRecommendation.item = parts[0].trim();
              currentRecommendation.recommendation = parts[1].trim();
            } else {
              currentRecommendation.recommendation = currentRecommendation.item;
              currentRecommendation.item = line.trim();
            }
          }
        }
      }
    });

    // Push the last recommendation to the array
    if (currentRecommendation) {
      recommendations.push(currentRecommendation);
    }

    if (recommendations.length === 0) {
      recommendations.push({
        recommendationNumber: 1,
        item: 'Default Recommendation',
        recommendation: 'Add a Class ABC to get started with understanding the fundamentals of Object-Oriented Programming.'
      });
    }
    return recommendations;
  }

  const updateProgressForTutorial = async (levelC, progLang) => {
    const response = await fetch("api/tutorials/updateProgress", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.user.token}`,
      },
      body: JSON.stringify({
        userId: user.user.userID,
        tutorialId: tutorial._id,
        progLang: progLang,
        levelNumber: levelC,
      }),
    });
    return response;
  };

  useEffect(() => {

    if (project) {
      setcodeState(
        project?.project?.codeStates?.[project.project.codeStates.length - 1] ||
        "----"
      );
      undoButton(false);
    } else if (tutorial) {
      const levelClickedNumber = parseInt(levelClicked, 10);
      const filteredLevels = tutorial.level.filter(
        (level) => level.progLang === language
      );
      const currentClickedLevel = filteredLevels[levelClickedNumber - 1];
      setClickedLevel(currentClickedLevel);
    
    }
  }, [
    project,
    tutorial,
    levelClicked,
    language,
    currentStepIndex,
    isObjectifyingTutorial,
  ]);

  const saveCodeChanges = async () => {
    //get codeFilez changes and save the lines inside the proj database
    const newCodeData = codeFiles.map(
      (fileKey) => {
        return {
          fileName: fileKey.fileName,
          code: fileKey.code,
        };
      }
    );

    try {
      const fetchUpdatedResponse = await fetch(
        `/api/projects/${project?.project?._id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.user.token}`,
          },
          body: JSON.stringify({ codeData: newCodeData }),
        }
      );
      if (!fetchUpdatedResponse.ok) {
        message.error("Something went wrong. Try again");
      }
      else {
        message.success("Changes saved");
      }
    }
    catch (error) {
      message.error(error.statusText);
    }


  };

  const getOOPConceptGroups = () => {
    const concepts = codeState?.relationships || [];

    // Group concepts by type
    const groupedConcepts = concepts.reduce((groups, concept) => {
      const key = concept.type;
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(concept);
      return groups;
    }, {});

    // Organize groups by type and sort them
    const organizedGroups = Object.values(groupedConcepts).sort(
      (groupA, groupB) => {
        const nameA = groupA[0]?.type || "";
        const nameB = groupB[0]?.type || "";
        return nameA.localeCompare(nameB);
      }
    );

    return organizedGroups;
  };

  const organizedOOPConcepts = getOOPConceptGroups();
  const currentOOPConceptGroup =
    organizedOOPConcepts[currentConceptState - 1] || [];


  const handleNextBig = (number) => {
    if (number === 1 && currentConceptState < organizedOOPConcepts.length) {
      setCurrentConceptState(currentConceptState + 1);
    }
  };

  const handlePrevBig = (number) => {
    if (number === 1 && currentConceptState > 0) {
      setCurrentConceptState(currentConceptState - 1);
    }
  };

  const handleNext = (number) => {
    if (tutorial && currentStepIndex < clickedLevel.noTutSteps - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      updateProgressForTutorial(
        clickedLevel.levelNumber,
        clickedLevel.progLang
      );
      if (linesHighlighted === undefined) {
//clickedLevel?.linesOfCode?.lines[0]
        setLinesHighlighted("1-1");
      } else {
        setLinesHighlighted("2-3");
      }
    } else if (project) {
      if (number === 1) {
        if (currentClassState < generatedSummaries.length - 1) {
          // Move to the next class instance
          setCurrentClassState(currentClassState + 1);
          if (linesHighlighted === undefined) {
            setLinesHighlighted(codeState?.classes[0]?.linesOfCode);
          } else {
            setLinesHighlighted(codeState?.classes[currentClassState + 1]?.linesOfCode);
          }

        } else {
          const nextInstanceIndex = currentInstanceState + 1;
          if (nextInstanceIndex < currentOOPConceptGroup.length) {
            setCurrentInstanceState(nextInstanceIndex);
          } 
          else {
            // Reset instance index when reaching the end
            setCurrentInstanceState(0);
            // Move to the next concept if available
            const nextConceptIndex = currentConceptState + 1;
            if (nextConceptIndex < organizedOOPConcepts.length+1) {
              setCurrentConceptState(nextConceptIndex);
            } else {
              // If there is only one instance in the last concept, move to the next concept
              setCurrentConceptState(0);
            }
          }
          if (currentOOPConceptGroup[currentInstanceState + 1]?.linesOfCode === undefined && organizedOOPConcepts[currentConceptState] ) {
            setLinesHighlighted(organizedOOPConcepts[currentConceptState][0]?.linesOfCode);
          } else if (currentOOPConceptGroup[currentInstanceState + 1]?.linesOfCode && currentOOPConceptGroup ){
            setLinesHighlighted(currentOOPConceptGroup[currentInstanceState + 1]?.linesOfCode);
          }
        }


      }
      else if (
        number !== 1 &&
        currentRecommendation < generatedRecommendations.length - 1 && currentRecommendation >= 0
      ) {
        setCurrentRecommendation(currentRecommendation + 1);
      }
    }
  };
  useEffect(() => {
    // This effect will run whenever linesHighlighted changes
    handleUpdateLinesHighlighted(linesHighlighted);
    
  }, [linesHighlighted]);


const handlePrev = (number) => {
  if (tutorial && currentStepIndex > 0) {
    setCurrentStepIndex(currentStepIndex - 1);
    setLinesHighlighted("1-1");
  } else if (project) {
    if (number === 1) {
      if (currentInstanceState > 0) {
        // Move to the previous instance within the current concept
        setCurrentInstanceState(currentInstanceState - 1);
        setLinesHighlighted(currentOOPConceptGroup[currentInstanceState-1]?.linesOfCode);
      } else if (currentConceptState > 0) {
        // Move to the previous concept
        setCurrentConceptState(currentConceptState - 1);
        const prevConceptInstances = currentOOPConceptGroup[currentConceptState]?.length;
        setCurrentInstanceState(prevConceptInstances ? prevConceptInstances - 1 : 0);
        setLinesHighlighted(organizedOOPConcepts[currentConceptState-1][0]?.linesOfCode);
      } else if (currentClassState > 0) {
        // Move to the previous class instance
        setCurrentClassState(currentClassState - 1);
        setLinesHighlighted(codeState?.classes[currentClassState - 1]?.linesOfCode);
      } else {
        // Handle the case when reaching the beginning
        // Reset instance index when reaching the beginning
        setCurrentInstanceState(0);
        // Move to the last concept if available
        setCurrentConceptState(organizedOOPConcepts.length - 1);
        // Move to the last instance of the last concept
        setCurrentInstanceState(currentOOPConceptGroup[organizedOOPConcepts.length - 1]?.length - 1);
        setLinesHighlighted(organizedOOPConcepts[currentConceptState-1][0]?.linesOfCode);
     }
    } else if (
      number !== 1 &&
      currentRecommendation < generatedRecommendations.length &&
      currentRecommendation > 0
    ) {
      setCurrentRecommendation(currentRecommendation - 1);
    }
  }
};

  const generateClassSummaries = (classes, abstractClasses) => {
    const summaries = [];

    // Generate class summaries
    classes.forEach((classInfo) => {
      if (classInfo.isClass && !abstractClasses.includes(classInfo.name)) {
        let classDescription = `${classInfo.name} is a blueprint for creating objects.`;

        // Attributes
        if (classInfo.attributes && classInfo.attributes.length > 0) {
          const attributesDescription = ` Each object created from this class will have are ${classInfo.attributes
            .map((attribute) => attribute.name)
            .join(", ")}`;
          classDescription += attributesDescription;
        }

        // Methods
        if (classInfo.methods && classInfo.methods.length > 0) {
          const methodsDescription = ` and functions that describe what those objects can do, like ${classInfo.methods
            .map((method) => method.name)
            .join(", ")}`;
          classDescription += methodsDescription;
        }
        classDescription += ".";

        summaries.push(classDescription);
      }
    });

    return summaries;
  };

  const generateOOPSummaries = (relationships) => {
    const oopsummaries = [];
    // Relationship descriptions
    relationships.forEach((relationship) => {
      let description;

      switch (relationship.type) {
        case "polymorphism":
          const sourceName = relationship.source.name?.split(".")[0];
          const targetName =
            relationship.target.name?.split(".")[0] || "another class";
          description = `Objects of different classes (such as ${targetName}, and ${sourceName}) are treated as variant forms of ${sourceName} since it is the parent, enabling code to operate on them uniformly despite their differing specific implementations.`;
          break;

        case "method overriding":
          const sourceNameOverride = relationship.source.name;
          const targetNameOverride = relationship.target.name;
          description = `${targetNameOverride} provides its own version of a method that is already defined in ${sourceNameOverride}, customizing its behavior.`;
          break;

        case "method overloading":
          const sourceNameOverload = relationship.source.name;
          const functionName = relationship.target.name;
          description = `${sourceNameOverload} defines multiple methods with the same name- ${functionName} but different parameters, providing flexibility in how it can be used.`;
          break;

        case "inheritance":
          const sourceNameInheritance = relationship.source.name;
          const targetNameInheritance = relationship.target.name;
          description = `${sourceNameInheritance} inherits features from ${targetNameInheritance}, allowing it to reuse and extend functionality from an existing class.`;
          break;

        case "encapsulation":
          const sourceNameEncapsulation = relationship.source.name;
          description = `${sourceNameEncapsulation} hides the implementation details of its attributes and methods, protecting them from direct access and modification.`;
          break;

        case "abstract class":
          const sourceNameAbstract = relationship.source.name;
          description = `${sourceNameAbstract} is a blueprint for other classes. It may have abstract methods that must be implemented by its subclasses.`;
          break;

        case "interface":
          const interfaceName = relationship.target.name;
          const className = relationship.source.name;
          description = `The ${interfaceName} interface defines certain standardized behaviors, and the ${className} class implements these behaviors rather than redefining it on its own.`;
          break;
        default:
          description = "No explanation necessary :)";
      }

      oopsummaries.push(description);
    });

    return oopsummaries;
  };
  // Populate abstractClasses array
  const abstractClasses = [];
  codeState?.relationships?.forEach((relationship) => {
    if (relationship.type === "abstract class") {
      abstractClasses.push(relationship.source.name);
    }
  });

  const generatedSummaries = generateClassSummaries(
    codeState?.classes || [],
    abstractClasses
  );
  const generatedOOPSummaries = generateOOPSummaries(currentOOPConceptGroup);

  const handleUndoClick = () => {
    // Set the state to true when the undo button is clicked
    undoButton(true);
    // Perform any additional actions you need for undo here
  };


  return (
    <>
      <div
        className={`${styles.defaultAnimationContainer} ${AnimationContainer}`}
      >
        <div className={styles.frame}>
          <div className={styles.animation}>
            {tutorial && clickedLevel ? (
              <TutorialStep
                oopConceptTitles={`Step ${currentStepIndex + 1}`}
                conceptTitle="/vector.svg"
                conceptDescription="/vector.svg"
                conceptCode=""
                conceptCodeImageUrls=""
                carMake={
                  clickedLevel?.tutorialSteps[currentStepIndex]?.substring(
                    clickedLevel.tutorialSteps[currentStepIndex].indexOf(":") +
                    1
                  ) || "Loading from the database..."
                }
                conceptCodeDimensions={`${currentStepIndex + 1 || 1} of ${clickedLevel.noTutSteps
                  }`}
                background='#0D67FF'
                top='5px'
                lefto='176px'
                onNext={handleNext} // Pass the next step handler
                onPrev={handlePrev} // Pass the previous step handler

              />
            ) : (
              <>
                {/* Render classes */}
                {generatedSummaries.length > 0 && (
                  <TutorialStep
                    oopConceptTitles={`Classes`}
                    conceptNumber={`${currentConceptState + 1
                      } of ${organizedOOPConcepts.length + 1 || 1}`}
                    conceptTitle="/vector.svg"
                    conceptDescription="/vector.svg"
                    conceptCode="/vector.svg"
                    conceptCodeImageUrls="/vector.svg"
                    carMake={`${generatedSummaries[currentClassState] || " "
                      }`}
                    conceptCodeDimensions={`${currentClassState + 1} of ${generatedSummaries.length || 1
                      }`}
                    onNext={() => handleNext(1)}
                    onPrev={() => handlePrev(1)}
                    onNextBig={() => handleNextBig(1)}
                    onPrevBig={() => handlePrevBig(1)}
                    background="#6f47eb"
                  />
                )}

                {/* Render other OOP concepts */}
                {currentConceptState >= 0 && currentOOPConceptGroup.length > 0 && (
                  <TutorialStep
                    oopConceptTitles={` ${currentOOPConceptGroup[0]?.type
                      ? currentOOPConceptGroup[0]?.type
                        .split(" ")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")
                      : '' || " "
                      }`}
                    conceptNumber={` ${currentConceptState + 1} of ${organizedOOPConcepts.length + 1
                      }`}
                    conceptTitle="/vector.svg"
                    conceptDescription="/vector.svg"
                    conceptCode="/vector.svg"
                    conceptCodeImageUrls="/vector.svg"
                    carMake={`${generatedOOPSummaries[currentInstanceState] || " "
                      }`}
                    conceptCodeDimensions={`${currentInstanceState + 1} of ${currentOOPConceptGroup.length || 1
                      }`}
                    onNext={() => handleNext(1)}
                    onPrev={() => handlePrev(1)}
                    onNextBig={() => handleNextBig(1)}
                    onPrevBig={() => handlePrevBig(1)}
                    background="#6f47eb"
                  />
                )}

                {isEditPlusClicked && (
                  <TutorialStep
                    oopConceptTitles={` ${generatedRecommendations[currentRecommendation]?.item ||
                      "Waiting for Addition Recommended"
                      }`}
                    conceptTitle="/vector.svg"
                    conceptDescription="/vector.svg"
                    carMake={`${generatedRecommendations[currentRecommendation]
                      ?.recommendation || ""
                      }`}
                    conceptCodeDimensions={` ${currentRecommendation + 1} of ${generatedRecommendations.length
                      }`}
                    propTop="66px"
                    propLeft="15px"
                    propRight="51.74%"
                    propLeft1="46.86%"
                    propRight1="48.66%"
                    propLeft2="49.95%"
                    onNext={() => handleNext(2)}
                    onPrev={() => handlePrev(2)}
                    isEditPlusClicked={isEditPlusClicked}
                    onImplement={() => generateCode(generatedRecommendations[currentRecommendation]?.recommendation)}
              
                    background="#1E9F42" //green colour fro step bar
                  />
                )}

                {isEditMinusClicked && (
                  <TutorialStep
                    oopConceptTitles={`${generatedRecommendations[currentRecommendation]?.item ||
                      "Waiting for Deletion Recommended"
                      } `}
                    conceptTitle="/vector.svg"
                    conceptDescription="/vector.svg"
                    carMake={`${generatedRecommendations[currentRecommendation]
                      ?.recommendation || " "
                      }`}
                    conceptCodeDimensions={`${currentRecommendation + 1} of ${generatedRecommendations.length
                      }`}
                    propTop="66px"
                    propLeft="15px"
                    propRight="51.74%"
                    propLeft1="46.86%"
                    propRight1="48.66%"
                    propLeft2="49.95%"
                    onNext={() => handleNext(2)}
                    onPrev={() => handlePrev(2)}
                    isEditMinusClicked={isEditMinusClicked}
                    onImplement={() => generateCode(generatedRecommendations[currentRecommendation]?.recommendation)}
                    background="#DE3535" //red for deletion
                  />
                )}
              </>
            )}
          </div>
          <div className={styles.arrowContainer}>
            {tutorial ? (
              <>
                {isObjectifyingTutorial && (
                  <AnimationDatabase
                    tutId={tutorial._id}
                    level={clickedLevel}
                  />
                )}
              </>
            ) : (
              <>
                {isObjectifyingProject && (

                  <AnimationDatabase
                    projId={project.project._id}
                    proj={project}
                  />
                )}
              </>
            )}
          </div>
        </div>

        <div className={styles.frame1}>
          <div className={styles.topBarAnimation}>
            <div className={styles.animation1}>
              <div className={styles.icon}>
                <img className={styles.vectorIcon} alt="" />
                <img
                  className={styles.vectorIcon1}
                  alt=""
                  src="/vector5@2x.png"
                />
                <b className={styles.js}>AG</b>
              </div>
              <div className={styles.animation2}>Animation</div>
            </div>

            <div className={styles.saveButton}>
              <div className={styles.ellipseParent} >
                <div className={`${styles.frameChild} ${tutorial ? styles.disabled : ''}`} onClick={handleEditPlusClick}>
                  <img
                    className={styles.editPlus}
                    alt=""
                    src="/edit--plus.svg"
                  />
                </div>
              </div>

              <div
                className={styles.frameParent}
                onClick={handleEditMinusClick}
              >
                <div className={styles.ellipseWrapper}>
                  <div className={`${styles.frameItem} ${tutorial ? styles.disabled : ''}`}>
                    <img
                      className={styles.editMinus}
                      alt=""
                      src="/edit--minus.svg"
                    />
                  </div>
                </div>
              </div>

              <button
                className={`${styles.explorebutton} ${tutorial ? styles.disabled : ""
                  }`}
                disabled={tutorial}
                onClick={() => {
                  handleUndoClick(); // Call the handler for undo button click
                }}
              >
                <div className={styles.undo}>Undo</div>
                <img
                  className={styles.undoButtonIcon}
                  alt=""
                  src="/undo-button.svg"
                />
              </button>
              <button
                className={`${styles.explorebutton1} ${tutorial ? styles.disabled : ""
                  }`}
                onClick={() => saveCodeChanges()}
                disabled={tutorial}
              >
                <div className={styles.undo}>Save</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AnimationContainer;
