import React, { useState, useEffect, useCallback } from "react";
import AceEditor from 'react-ace';
import 'brace/mode/javascript';
import 'brace/theme/tomorrow';
import Property1Default from "../components/Property1Default";
import Property1Closed from "../components/Property1Closed";
import { useLocation } from "react-router-dom";
import { useProjectsContext } from "../hooks/useProjectsContext";
import { useTutorialsContext } from "../hooks/useTutorialsContext";
import { useNavigate } from "react-router-dom";
import LogOutPopOutL from "../components/LogOutPopOutL";
import PortalPopup from "../components/PortalPopup";
import Footer from "../components/Footer";
import Property1Default2 from "../components/Property1Default2";
import FileNavigationContainer from "../components/FileNavigationContainer";
import OutputContainer from "../components/OutputContainer";
import AnimationContainer from "../components/AnimationContainer";
import styles from "./CodeEditor.module.css";

import { useAuthContext } from "../hooks/useAuthContext";
import { useCodeFilesContext } from "../hooks/useCodeFilesContext";
import { CodeFilesContext } from "../context/CodeFilesContext";

const CodeEditor = () => {
  const user = useAuthContext();
  const { project, dispatch: projectDispatch } = useProjectsContext();
  const { tutorial, dispatch: tutorialDispatch } = useTutorialsContext();
  const { state } = useLocation();
  const { state: { codeFiles: contextCodeFiles } } = useCodeFilesContext();
  const { dispatch: dispatchCodeFiles } = useCodeFilesContext();
  const [progLang, setprogLang] = useState("");

  // const [codeFiles, setCodeFiles] = useState(contextCodeFiles || []);
  // const handleCodeFilesUpdate = (updatedCodeFiles) => {
  //   setCodeFiles(updatedCodeFiles);
  // };

  

  const projectId = state ? state.ProjectId : "65d57921b81b7f5c349e0705";
  const tutorialId = state ? state.tutorialId : null;
  const levelClicked = state ? state.levelClicked : null;
  const language = state ? state.language : null;

 
  const [isLogOutPopOutLPopupOpen, setLogOutPopOutLPopupOpen] = useState(false);
  const [isLogOutPopOutLPopup1Open, setLogOutPopOutLPopup1Open] =
    useState(false);
  const navigate = useNavigate();

  const onFrameButtonClick = useCallback(() => {
    handleUnload();
    navigate("/CodeEditor");
    // Please sync "Code Editor- after login" to the project
  }, [navigate]);

  const onVoopClick = useCallback(() => {
    if (projectId === "65d57921b81b7f5c349e0705") {
      handleUnload();
      navigate("/")
    } else {
      navigate("/Dashboard");
    }
  }, [navigate]);

  const onFrameContainer3Click = useCallback(() => {
    navigate("/Tutorials");
  }, [navigate]);

  const onFrameIconClick = useCallback(() => {
    // Please sync "MyProjects-L" to the project
    navigate("/Projects");
  }, [navigate]);

  const onUsericonClick = useCallback(() => {
    navigate("/Profile");
  }, [navigate]);

  const onDashoboardSMContainerClick = useCallback(() => {
    navigate("/Dashboard");
  }, [navigate]);

  const onFrameButtonClickSignIn = useCallback(() => {
    handleUnload();
    navigate("/LogIn");
    // Please sync "Code Editor- after login" to the project
  }, [navigate]);


  const handleUnload = async () => {
    try {
      if (projectId === "65d57921b81b7f5c349e0705") {


        const response = await fetch(`api/projects/65d57921b81b7f5c349e0705/clearCodeStates`);
        const json = await response.json();

        if(progLang!==undefined){
          if(json.project.progLang ==="c++"){
            setprogLang("java")
          } else {setprogLang(json.project.progLang);}
        }

      }
    } catch (error) {
      console.error("error", error)
    }
  };

  const fetchProjects = async () => {
    try {
      
      
      // Dispatch a synchronous action to indicate the start of the fetch operation
      projectDispatch({ type: 'FETCH_PROJECTS_START' });

      let headers = {};
      if (projectId !== "65d57921b81b7f5c349e0705" && user.user.userEmail) {
        headers = { 'Authorization': `Bearer ${user.user.token}` };
      }
      const response = await fetch(`/api/projects/${projectId}`, {
        headers: headers
      });
      const json = await response.json();

        if(progLang!==undefined){
          if(json.project.progLang ==="c++"){
            setprogLang("java")
          } else {setprogLang(json.project.progLang);}
        }

      if (response.ok) {
        // Dispatch a synchronous action to update the project context with the fetched data
        projectDispatch({ type: 'GET_PROJECT', payload: json });

        // Update the code files context from the fetched data
        const length = json?.project?.codeStates.length;
        const loadedCodeFiles = json?.project?.codeStates[length - 1]?.codeData;
        dispatchCodeFiles({ type: 'UPDATE_CODE', payload: loadedCodeFiles });
        dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: 0 });

      }

      // Dispatch a synchronous action to indicate the end of the fetch operation
      projectDispatch({ type: 'FETCH_PROJECTS_SUCCESS' });
    } catch (error) {
      console.error('Error fetching project:', error);
      // Dispatch a synchronous action to indicate the end of the fetch operation with an error
      projectDispatch({ type: 'FETCH_PROJECTS_FAILURE', payload: error });
    }
  };


  const fetchTutorials = async () => {

    if (tutorialId) {
      try {
        tutorialDispatch({ type: 'FETCH_TUTORIAL_START' });
        const response = await fetch(`/api/tutorials/${tutorialId}`, {
          headers: { 'Authorization': `Bearer ${user.user.token}` }
        });
        const json = await response.json();
        if(progLang!==undefined){
          if(json.project?.progLang ==="c++"){
            setprogLang("java")
          } else {setprogLang(json.project?.progLang);}
        }

        if (response.ok) {

          tutorialDispatch({ type: 'GET_TUTORIAL', payload: json });
          const filteredLevels = json?.level?.filter((level) => level.progLang === language);
          dispatchCodeFiles({ type: 'UPDATE_CODE', payload: filteredLevels[levelClicked - 1].codeData });
          dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: 0 });

        }
        tutorialDispatch({ type: 'FETCH_TUTORIAL_SUCCESS' });

      } catch (error) {
        tutorialDispatch({ type: 'FETCH_TUTORIAL_FAILURE', payload: error });

        console.error('Error fetching tutorial:', error);
      }
    }

  };

  useEffect(() => {

    fetchProjects();


     fetchTutorials(); 

    // Fetch projects only if projectId is present
  
    if (projectId && !user?.user?.userEmail) {
      fetchProjects();
    } else if (projectId && user.user.userEmail) {
      
      fetchProjects();
    } else if (tutorialId && user.user.userEmail) {
     
      fetchTutorials();
    }


    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, [user, projectDispatch, tutorialDispatch, projectId, tutorialId]);







  const [undoButtonClicked, setUndoButtonClicked] = useState(false);
  const handleUndoButtonClick = useCallback((value) => {
    setUndoButtonClicked(value);
  }, []);

  

  const [linesHighlighted, setLinesHighlighted] = useState([]);
  const updateLinesHighlighted = useCallback((newLinesHighlighted) => {
    setLinesHighlighted(newLinesHighlighted);
  }, []);

  return (
    <div>
      {user.user ? (
        <div className={styles.CodeEditor}>
          <div className={styles.frameGenericTutorial}>
            {project && <FileNavigationContainer key={projectId} project={project} setUndoButtonClicked={handleUndoButtonClick} />}
            {tutorial && <FileNavigationContainer key={tutorialId} tutorial={tutorial} levelClicked={levelClicked} language={language} setUndoButtonClicked={handleUndoButtonClick} />}

            {project && <AnimationContainer key={projectId} project={project} undoButton={handleUndoButtonClick}  updateLinesHighlighted={updateLinesHighlighted}/>}
            {tutorial && <AnimationContainer tutorial={tutorial} levelClicked={levelClicked} language={language} updateLinesHighlighted={updateLinesHighlighted} />}

            <div className={styles.mainWrapper}>
              {project && <OutputContainer key={projectId} project={project} undoButton={undoButtonClicked} linesHighlighted={linesHighlighted} ProgramLang={progLang} />}
              {tutorial && <OutputContainer key={tutorialId} tutorial={tutorial} levelClicked={levelClicked} language={language}  ProgramLang={progLang} linesHighlighted={linesHighlighted}/>}
            </div>
          </div>
          <Property1Closed
            onFrameContainerClick={onFrameContainer3Click}
            onFrameIconClick={onFrameIconClick}
            onUsericonContainerClick={onUsericonClick}
            onDashoboardSMContainerClick={onDashoboardSMContainerClick}
          />

          <Property1Default
            onFrameButtonClick={onFrameButtonClick}
            onVoopClick={onVoopClick}
          />
          <Footer />
        </div>

      ) : (
        <div className={styles.CodeEditor}>

          <div className={styles.frameGenericTutorial}>
            <FileNavigationContainer customClassName={styles.customFileNavigation} project={project} />
            <img
              className={styles.frameGenericTutorialChild}
              alt=""
              src="/line-7@2x.png"
            />
            <div className={styles.mainWrapper}>
              <OutputContainer customOutputContainer={styles.customOutputContainer} project={project} undoButton={undoButtonClicked} linesHighlighted={linesHighlighted}
              />
            </div>
            <AnimationContainer AnimationContainer={styles.AnimationContainer} project={project} undoButton={handleUndoButtonClick}updateLinesHighlighted={updateLinesHighlighted} />
          </div>
          <Property1Default2
            buttonText="codeEditorButtonHeader"
            actionButtonText="Log In"
            property1DefaultAlignContent="stretch"
            property1DefaultJustifyContent="unset"
            property1DefaultPosition="absolute"
            property1DefaultTop="0px"
            property1DefaultLeft="0px"
            buttonPadding="var(--padding-smi) 0px"
            buttonOverflow="unset"
            textDisplay="inline-block"
            textWidth="unset"
            textFlexShrink="unset"
            textCursor="pointer"
            backgroundColor={ "#1b1a1f" }
            onFrameButtonClick={onFrameButtonClick}
            onFrameButtonClickSignIn={onFrameButtonClickSignIn}
            onVoopClick={onVoopClick}
          />
          <Footer />

        </div>
      )}


    </div>
  );

};
export default CodeEditor;