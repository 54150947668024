// parentClassData.js

let parentClassMethods = [];
let linesOfCode = [];

export const getParentClassMethods = () => parentClassMethods;

export const saveParentClassMethods = (methods) => {
  parentClassMethods = methods;
};


export const getLinesOfCode = () => linesOfCode;

export const saveLinesOfCode = (loc) => {
  linesOfCode = loc;
};