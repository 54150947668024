

import React, { useState, useRef } from 'react';
import { useCodeFilesContext } from '../hooks/useCodeFilesContext';
import { getInheritanceAbstractRelationships } from './relationshipData';
import {message} from 'antd';

const Method = ({ id, methodName, onDelete, style, parent }) => {
  const AllRelationships = getInheritanceAbstractRelationships();
  const inheritanceAndInterface = AllRelationships.filter((rel) => rel.type === 'inheritance' || rel.type === 'interface');
  const [isEditing, setIsEditing] = useState(false);

  const [newMethodName, setNewMethodName] = useState(methodName);
  const newMethodNameRef = useRef(methodName); //to store the prev value
  const { state: { codeFiles, selectedFile }, dispatch: dispatchCodeFiles } = useCodeFilesContext();
  const [currentClass, setCurrentClass] = useState('');
  const handleMethodNameChange = (event) => {
    setNewMethodName(event.target.value);
  };


  const handleRename = () => {
    setIsEditing(false);
    // Update the code files with the new method name
    const length = codeFiles.length;
    for (let i = 0; i < length; i++) { //goes through number of code files
      const file = codeFiles[i];
      const updatedCode = [];
      let isChild = false;
      for (let j = 0; j < file.code.length; j++) {

        const line = file.code[j];

        const updatedLine = line.replace(new RegExp('\\b' + newMethodNameRef.current + '\\b', 'g'), newMethodName); //newMethodNameRef.current hold the value of the last changed variable
        updatedCode.push(updatedLine);
      }
      // Update the code of the current file with the updated code
      codeFiles[i].code = updatedCode;

    }
message.info("Method has been renamed in the code")
    // Dispatch an action to update the code files state

    dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: null });
    dispatchCodeFiles({ type: 'UPDATE_CODE', payload: codeFiles });
    // // Dispatch an action to update the code files state
    // codeFiles.dispatch({ type: 'UPDATE_CODE', payload: { codeFiles: updatedCodeFiles } });
    newMethodNameRef.current = newMethodName;
  };
  return (
    <div id={id} style={style}>
      {isEditing ? (
        <input
          type="text"
          value={newMethodName}
          onChange={handleMethodNameChange}
          onBlur={handleRename}
          autoFocus
          className="inputStyle"
        />
      ) : (
        <>
          <span
            onClick={onDelete}
            style={{
              cursor: 'pointer',
              color: 'black', // Initial color
              transition: 'color 0.1s', // Smooth transition effect
            }}
            onMouseEnter={(e) => { e.target.style.color = 'red'; }} // Change color on hover
            onMouseLeave={(e) => { e.target.style.color = 'black'; }} // Restore initial color
          >
            &#128465;&nbsp;
          </span>          <span onClick={() => setIsEditing(true)}>{newMethodName} (...) </span>

        </>
      )}
    </div>
  );
};

export default Method;
