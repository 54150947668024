import { useState , useEffect} from 'react'
import { useAuthContext } from './useAuthContext'
import { message } from 'antd';
export const useLogin = () => {
  const [isLoading, setIsLoading] = useState(null)
  const { dispatch } = useAuthContext()

  const login = async (userEmail, userPassword) => {
    setIsLoading(true)

    const response = await fetch('/api/user/login', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({ userEmail, userPassword })
    })
    const json = await response.json()

    if (!response.ok) {
      setIsLoading(false)
      message.error("Incorrect email or password. Please ensure you have an account with Voop."); // Throw an error if response is not ok
      return;
    }

    // save the user to local storage
    localStorage.setItem('user', JSON.stringify(json))

    // update the auth context
    dispatch({type: 'LOGIN', payload: json})

    // update loading state
    setIsLoading(false)
  }
  const updateUserDetails = (newFirstName) => {
    // Get the current user from local storage
    const currentUser = JSON.parse(localStorage.getItem('user'));
  
    // Update the firstName field of the current user
    currentUser.firstName = newFirstName;
  
    // Update local storage with the updated user details
    localStorage.setItem('user', JSON.stringify(currentUser));
  
    // Update the auth context if necessary
    dispatch({ type: 'UPDATE_USER_DETAILS', payload: currentUser });
  };
  return { login, isLoading, updateUserDetails }
}
