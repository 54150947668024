//ANIMATION
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import ReactFlow, {
  addEdge,
  updateEdge,
  Background,
  Controls,
  Handle,
  MiniMap,
} from "react-flow-renderer";
import { useNodesState, useEdgesState, MarkerType } from "reactflow";
import { useAuthContext } from "../hooks/useAuthContext";
import Class from "./Class";
import "./Animation.css";
import { setRelationshipColors } from "./relationshipColors";
import { saveLinesOfCode } from "./parentClassData";
import { saveDataToRelationshipData } from "./relationshipData";
import { useCodeFilesContext } from "../hooks/useCodeFilesContext";
import { message } from 'antd';

let methodOverridingRelationships = [];
let methodOverloadingRelationships = [];
var addClass;
var deleteClass;
let everyRelationship = [];
const nodeTypes = {
  custom: (props) => <Class {...props}  everyRelationship={everyRelationship} methodOverridingRelationships={methodOverridingRelationships} addClass={addClass} deleteClass={deleteClass} />,
  //interface: (props) => <InterfaceClass {...props} />,
};

const AnimationDatabase = ({ projId, tutId, level, proj }) => {
  const user = useAuthContext();
  const [elements, setElements] = useState([]);
  const initialNodes = [];
  const initialEdges = [];
  const [AllRelationship, setAllRelationship] = useState([]);


  const inheritanceWithAbstract = [];
  //const [inheritanceRelationships, setInheritanceRelationships] = useState([]);

  const [abstractClassNames, setAbstractClassNames] = useState([]);
  const defaultElementCounter=useRef(0);
  const {
    state: { codeFiles: codeFiles, editorRef: editorRef },
  } = useCodeFilesContext();
  const { dispatch: dispatchCodeFiles } = useCodeFilesContext();
  var isSourceAbstract = [];
  // Declare a variable to store the parent class name
  const parentClassNames = [];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);

  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  // Declare inheritanceRelationships and newEdges outside the fetchData function
  var inheritanceRelationships = [];
  var AllRelationships = [];
  var newEdges = [];
  const [nextPostion, setNextPostion] = useState(-200);
  const edgeUpdateSuccessful = useRef(true);
  const createDefaultElement = (position) => {

    let counter = defaultElementCounter.current;
    var id = `newClass_${defaultElementCounter.current}`; // Use the counter value to generate ID
    while (elements.some(element => element.id === id)) {
      counter++;
      id = `newClass_${counter}`;
    }
    defaultElementCounter.current=counter;

    setNextPostion(nextPostion + 200);
    defaultElementCounter.current=defaultElementCounter.current + 1;
    return {
      id: id,
      data: {
        name: id,
        attributes: [],
        methods: [],
      },
      position: { x: nextPostion, y: 0 }, //change position later *
      type: "custom",
      sourcePosition: "right",
      targetPosition: "left",
      className: "DefaultElement",
    };
  };
  const createNewEdge = (sourceClass, defaultElement, number) => {
    if (number == 4) { // child
      return {
        id: `${sourceClass}-${defaultElement.name}`,
        target: sourceClass,
        source: defaultElement.id,
        type: "smoothstep",
        animated: {
          duration: 20000, // Increase duration for slower animation
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 40,
          height: 40,
          color: "#aba4cf",
        },
        style: {
          stroke: "#beb6e6", // Different color
          strokeWidth: 2, // Thinner stroke width
          strokeDasharray: "20, ", // Increased spacing between dashes
          strokeDashoffset: 0, // Initial offset of the dashes
        },
        arrowHeadType: "arrow",
      };
    }
    else if (number == 1 || number == 3) { // parent or abstract parent
      return {
        id: `${sourceClass}-${defaultElement.name}`,
        source: sourceClass,
        target: defaultElement.id,
        type: "smoothstep",
        animated: {
          duration: 20000, // Increase duration for slower animation
        },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 40,
          height: 40,
          color: "#aba4cf",
        },
        style: {
          stroke: "#beb6e6", // Different color
          strokeWidth: 2, // Thinner stroke width
          strokeDasharray: "20, ", // Increased spacing between dashes
          strokeDashoffset: 0, // Initial offset of the dashes
        },
        arrowHeadType: "arrow",
      };
    }

  };

  

  const handleDeleteClass = (name) => {
    // Implement logic to delete the selected element (class, method, or attribute)

    // Example: Retrieve foldable lines from Ace Editor
    const session = editorRef.current.editor.getSession();
    const foldableLines = [];
    for (let i = 0; i < session.getLength(); i++) {
        const foldRange = session.getFoldWidgetRange(i);
        if (foldRange) {
            const startRow = foldRange.start.row; // Start row of the foldable range
            const endRow = foldRange.end.row; // End row of the foldable range

            // Get the content within the foldable range
            const content = session.getLines(startRow, endRow).join("\n");
            var contentFirstLine = session.getLine(startRow);
            //here if content has a line that has class and name
          
            const regexPattern = new RegExp(`^(public\\s+)?(class|interface|abstract)\\s+${name}\\s*(\\([^\\)]*\\))?\\s*:?\\s*`);

            var contentForDelete = [];
            if (regexPattern.test(contentFirstLine)) {
                contentForDelete = content.split("\n");
            

                //remove it from codeFiles
                const length = codeFiles.length;
                for (let i = 0; i < length; i++) {
                    //goes through number of code files
                    const file = codeFiles[i];
                    const updatedCode = [];
                    var lineToDelete = 0;
                    var foundDeletedClass = false;

                    for (let j = 0; j < file.code.length; j++) {
                        //check if the line is the same as the contentForDelete[0]

                        const line = file.code[j];

                        if (line.trim() === contentForDelete[0].trim()) {
                            foundDeletedClass = true;
                        }
                        if (foundDeletedClass) {
                            lineToDelete++;
                            if (lineToDelete <= contentForDelete.length) {
                                continue;
                            } else {
                                updatedCode.push(line);
                                continue;
                            }
                        }

                        updatedCode.push(line);
                    }
                    codeFiles[i].code = updatedCode;
                }

                dispatchCodeFiles({ type: "UPDATE_FILE_SELECTED", payload: null });
                dispatchCodeFiles({ type: "UPDATE_CODE", payload: codeFiles });
            }
            // Add the foldable range to the foldableLines array if needed
            foldableLines.push({ start: startRow + 1, end: endRow + 1 });
        }
    }

    // Now foldableLines contains an array of objects with start and end line numbers
   



    // Example
  };

  // delete class function
  const deleteClassElement = (className) => {
    const defaultElement = createDefaultElement();
    // Initialize updated arrays
    let updatedElements = elements;
    let updatedNodes = nodes;
    let updatedEdges = edges;

    // Filter out edges connected to the class being deleted
    updatedEdges = updatedEdges.filter((edge) => {
      if (edge.target === className) {
        // If the class is also a source in the same edge, delete its children
        if (updatedEdges.some((e) => e.source === edge.source)) {
          const childClassName = edge.source;
          updatedElements = updatedElements.filter(
            (element) => element.data.name !== childClassName
          );
          updatedNodes = updatedNodes.filter(
            (node) => node.data.label !== childClassName
          );
        }
        return false; // Remove the edge if the class is only a target
      }
      if (edge.source === className) {
        return false; // Remove the edge if the class is only a source
      }
      return true; // Keep the edge if it's not connected to the class being deleted
    });

    // Filter out the class from elements and nodes based on class name
    updatedElements = updatedElements.filter(
      (element) => element.data.name !== className
    );
    updatedNodes = updatedNodes.filter((node) => node.data.label !== className);

    // Update the state with the updated data
    setElements(updatedElements);
    setNodes(updatedNodes);
    setEdges(updatedEdges);


    // dispatchCodeFiles({ type: "DELETED_COMPONENT", payload: className });

    handleDeleteClass(className);
  };


  deleteClass = deleteClassElement;

  //Function to add a default element to the elements state
  const addDefaultElement = (sourceClass, number) => {
    const defaultElement = createDefaultElement();
    
    let newArray = []
    switch (number) {
      case 1: // Abstract class
        everyRelationship.push({
          source: {
            type: "class",
            name: sourceClass


          },
          target: {
            type: "class",
            name: defaultElement.id
          },
          type: { type: "abstract class" }
        });
        break;
      case 2: // Interface
        everyRelationship.push({
          source: {
            type: "class",
            name: sourceClass
          },
          target: {
            type: "interface",
            name: defaultElement.id
          },
          type: { type: "interface" }
        });
        break;
      case 3: // Parent class
        everyRelationship.push({
          source: {
            type: "class",
            name: sourceClass
          },
          target: {
            type: "class",
            name: defaultElement.id
          },
          type: { type: "inheritance" }
        });
        break;
      case 4: // Child class
        everyRelationship.push({
          source: {
            type: "class",
            name: defaultElement.id
          },
          target: {
            type: "class",
            name: sourceClass
          },
          type: { type: "inheritance" }
        });
        break;
      default:
        break;
    }


    if (number !== 0) {
      const newEdge = createNewEdge(sourceClass, defaultElement, number);
      setEdges((prevEdges) => [...prevEdges, newEdge]);
    }
    setElements((prevElements) => [...prevElements, defaultElement]);
    // const newMethodId = `method${addMethodCounter}`;

    // const newMethod = {
    //   id: newMethodId,
    //   name: `newMethod${addMethodCounter}`, // Default name for the new method
    //   //access_modifier: "public", // Default access modifier for the new method
    //   parameters: [],

    // };
    // setMethodChildren([...methodcChildren, newMethod]);
    // setAddMethodCounter(addMethodCounter + 1);

    const length = codeFiles.length;
    let push = false;

    for (let i = 0; i < length; i++) {
      //going through each file in the user's project

      const file = codeFiles[i];
      let indentation = "";
      const updatedCode = [];

      for (let j = 0; j < Object.keys(file?.code || {}).length; j++) { //going through each line of code


        let line = file.code[j]; //stores the line of code
        let newClassCode = '';
        let parts = '';
        if (proj?.project?.progLang === 'java') { //if its a java project add a java method
          switch (number) {
            case 1: // Abstract class
              newClassCode = `\npublic abstract class ${defaultElement.id}{\n\n//Add code here that the child classes must implement\n}`;
              if (line.includes(`class ${sourceClass}`)) {
                if (line.includes("extends")) {
                  message.warning("A class can only inherit from one parent");
                  break;
                }
                line = line.replace(line, `public class ${sourceClass} extends ${defaultElement.id} {`);
              }
              break;
            case 2: // Interface
              newClassCode = `\npublic interface ${defaultElement.id}{\n\n//Add code here\n}`;
              if (line.includes(`class ${sourceClass}`)) {
                if (line.includes("implements")) {
                  line = line.replace("{", `, ${defaultElement.id} {`);
                  break;
                }
                line = line.replace("{", ` implements ${defaultElement.id} {`);

              }
              break;
            case 3: // Parent class
              newClassCode = `\npublic class ${defaultElement.id}{\n\n//Add code here that the child classes could implement\n}`;
              // Modify sourceClass to indicate inheritance

              if (line.includes(`class ${sourceClass}`)) {
                if (line.includes("extends")) {
                  message.warning("A class can only inherit from one parent");
                  break;
                }
                line = line.replace(line, `public class ${sourceClass} extends ${defaultElement.id} {`);
              }
              break;
            case 4: // Child class
              newClassCode = `\npublic class ${defaultElement.id} extends ${sourceClass} {\n\t// Default constructor \n\tpublic  ${defaultElement.id}() {\n\t\tsuper(); // Call to the parent class's constructor to ensure any initialization defined in the parent's constructor is executed \n\t}\n\t// Additional initialization specific to the ${defaultElement.id} class can be added here\n}`;
              break;
            default:
              newClassCode = `\npublic class ${defaultElement.id}{\n\t// Default constructor\n\tpublic ${defaultElement.id}() {\n\t\t// This is where you can initialise the attributes to create objects\n\t}\n}`;
              break;
          }


        } else if (proj?.project?.progLang === 'python') { //if its a python project add a python method
          switch (number) {
            case 1: // Abstract class 
              newClassCode = `\nclass ${defaultElement.id}(ABC): \n\t# Add code here\n\tpass\n`;
              if (line.includes(`class ${sourceClass}`)) {
                line = line.replace(line, `class ${sourceClass}(${defaultElement.id}): \n\tpass`);
              }
              break;
            case 3: // Parent class
              newClassCode = `\nclass ${defaultElement.id}: \n\t# Add code here that the child classes can implement\n\tpass\n`;
              // Modify the line to indicate inheritance
              if (line.includes(`class ${sourceClass}`)) {
                newClassCode = `\nclass ${defaultElement.id}: \n\t# Add code here that the child classes can implement\n\tpass`;
                line = line.replace(line, `class ${sourceClass}(${defaultElement.id}):`);
              }
              break;
            case 4: // Child class
              newClassCode = `\nclass ${defaultElement.id}(${sourceClass}): \n\t# This constructor is called when a new Child object is created \n\tdef __init__(self):\n\t\tsuper().__init__()  # Call to the parent class's constructor\n\t\t# This ensures that any initialization defined in the parent's constructor is executed \n\t\t# Additional initialization specific to the Child class can be added here\n`;
              break;
            default:
              newClassCode = `\nclass ${defaultElement.id}: \n\tdef __init__(self):\n\t\tpass\n\t\t# Default Constructor. Add the rest of your implementation here
\n`;
              break;
          }

        }
        parts = newClassCode.split('\n');
        if (!line.includes("import") && !push) {
          parts.forEach((part) => {
            updatedCode.push(part);
          });
          push = true;
        }
        updatedCode.push(line);
      }

      codeFiles[i].code = updatedCode;




    }
    dispatchCodeFiles({ type: "UPDATE_FILE_SELECTED", payload: null });
    dispatchCodeFiles({ type: "UPDATE_CODE", payload: codeFiles });

    message.info("New blueprint has been added to the code ", 3)


  };

  addClass = addDefaultElement;

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  useEffect(() => {
    const methodMap = {};

    // proj.classes.forEach(classObj => {
    //   classObj.methods.forEach(method => {
    //     if (!methodMap[method.name]) {
    //       methodMap[method.name] = [];
    //     }
    //     methodMap[method.name].push({ name: method.name, parameters: method.parameters });
    //   });
    // });
    const fetchData = async () => {
      try {
        if (projId) {
          let headers = {};
          if (projId !== "65d57921b81b7f5c349e0705" && user.user.userEmail) {
            headers = {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.user.token}`,
            };
          }
          const response = await fetch(`/api/projects/${projId}`, {
            method: "GET",
            headers: headers,
          });

          if (!response.ok) {
            console.error("Failed to fetch. Status:", response.status);
            return;
          }

          const data = await response.json();
          if (!data || !Array.isArray(data.project.codeStates)) {
            console.error(
              "Invalid response format. Expected an array of classes."
            );
            console.error("Actual Data:", data); // Log the entire data
            return;
          }


          // Adjust the data structure to include name, attributes, and methods


          const elementsArray = data.project.codeStates.slice(-1).flatMap((codeState, index) => {
            const classes = codeState.classes;
            const relationships = codeState.relationships;
            let c = 0;
            relationships.map((rel) => {
              if (rel.type === 'method overloading') {

                inheritanceWithAbstract.push({
                  source: {
                    type: rel.type,
                    name: rel.source.name
                  },
                  target: {
                    type: rel.type,
                    name: rel.target.name
                  },
                  type: rel.type
                });
              }
            });
            saveDataToRelationshipData({ inheritanceWithAbstract });


              inheritanceRelationships = relationships
                .filter(
                  (relationship) =>
                    relationship.type === "inheritance" &&
                    relationship.target &&
                    relationship.target.type === "class"
                )
                .map((relationship) => ({
                  source: {
                    type: relationship.source.type,
                    name: relationship.source.name,
                  },
                  target: {
                    type: relationship.target.type,
                    name: relationship.target.name,
                  },
                }));

              newEdges = inheritanceRelationships.map(({ source, target }) => ({
                id: `${source.name}-${target.name}`, // Unique identifier for the edge
                source: `${source.name}`, // ID of the source node
                target: `${target.name}`, // ID of the target node
                type: "smoothstep",
                animated: {
                  duration: 20000, // Increase duration for slower animation
                },
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  width: 40,
                  height: 40,
                  color: "#aba4cf",
                },
                style: {
                  stroke: "#beb6e6", // Different color
                  strokeWidth: 2, // Thinner stroke width
                  strokeDasharray: "20, ", // Increased spacing between dashes
                  strokeDashoffset: 0, // Initial offset of the dashes
                },
                arrowHeadType: "arrow",
              }));


              setEdges(newEdges);

              const updatedColors = {};

              AllRelationships = relationships.map((relationship) => ({
                source: {
                  type: relationship.source.type,
                  name: relationship.source.name,
                },
                target: {
                  type: relationship.target.type,
                  name: relationship.target.name,
                },
                type: {
                  type: relationship.type,
                },
              }));

            AllRelationships.forEach(({ target, source, type }) => {
              // Generate random colors for source and target
              const randomColor = getRandomColor();

              setAllRelationship(AllRelationship);

              //everyRelationship.push(AllRelationships);
              // Identify abstract classes , store them in an array calle dinheritancewithabstract
              // use a variable called usedColors to store the colour of the class

                const abstractClasses = relationships.filter(
                  (rel) => rel.type === "abstract class"
                );
                const usedColors = {};

                relationships.forEach((rel) => {
                  if (type.type === "implements" || type.type === "interface") {
                    inheritanceWithAbstract.push({
                      source: source.name,
                      target: target.name,
                      type: target.type,
                    });
                    // Check if target color has not been assigned
                    const randomColor1 = getRandomColor();
                    updatedColors[source.name] = randomColor1; // Update target color only if it's not already assigned

                    // Check if target color has not been assigned
                    const randomColor = getRandomColor();
                    updatedColors[target.name] = randomColor; // Update target color only if it's not already assigned
                  } else if (rel.type === "inheritance") {
                    isSourceAbstract = abstractClasses.some(
                      (abstract) => abstract.source.name === rel.target.name
                    );

                    // Set abstract class names state
                    setAbstractClassNames(abstractClassNames);
                    if (isSourceAbstract) {
                      inheritanceWithAbstract.push({
                        source: rel.source.name,
                        target: rel.target.name,
                        type: rel.type,
                      });
                      if (!usedColors[rel.source.name]) {
                        const randomColor1 = getRandomColor();
                        updatedColors[rel.source.name] = randomColor1;
                        const randomColor = getRandomColor();
                        usedColors[rel.source.name] = randomColor;
                      } else {
                        updatedColors[rel.source.name] =
                          usedColors[rel.source.name];
                      }
                      updatedColors[rel.target.name] = "grey"; // Assuming 'grey' is the color for abstract classes
                  
                    }
                  }
                  saveDataToRelationshipData({ inheritanceWithAbstract });
                });

              if (type.type === 'inheritance') {
                if (!(source.name in updatedColors)) {
                  const randomColor = getRandomColor();
                  updatedColors[source.name] = randomColor;
                }
                if (!(target.name in updatedColors)) {
                  const randomColor = getRandomColor();
                  updatedColors[target.name] = randomColor;
                }
                inheritanceWithAbstract.push({
                  source: source.name,
                  target: target.name,
                  type: type.type
                });

              }

              else {
                if (!(source.name in updatedColors)) { // Check if target color has not been assigned
                  updatedColors[source.name] = randomColor; // Update target color only if it's not already assigned
                }
                if (!(target.name in updatedColors)) { // Check if target color has not been assigned
                  updatedColors[target.name] = randomColor; // Update target color only if it's not already assigned
                }
              }
              saveDataToRelationshipData({ inheritanceWithAbstract });
            });
            everyRelationship = AllRelationships;

              // if (inheritanceWithAbstract.length > 0) {
              //   for (const relationship of inheritanceWithAbstract) {
              //     parentClassNames.push(relationship.target);
              //   }
              // }

              // Store the parent class names
              if (inheritanceWithAbstract.length > 0) {
                for (const relationship of inheritanceWithAbstract) {
                  const parentName = relationship.target;
                  if (!parentClassNames.includes(parentName)) {
                    parentClassNames.push(parentName);
                  }
                }
              }


              function getRandomColor() {
                const letters = "89ABCDEF";
                let color = "#";

                do {
                  color = "#"; // Reset color
                  for (let i = 0; i < 6; i++) {
                    color +=
                      letters[Math.floor(Math.random() * letters.length)];
                  }
                } while (!isLightColor(color));

                return color;
              }

              // Function to check if a color is light
              function isLightColor(color) {
                const hex = color.replace("#", "");
                const rgb = parseInt(hex, 16); // Convert hex to decimal
                const r = (rgb >> 16) & 0xff; // Extract red component
                const g = (rgb >> 8) & 0xff; // Extract green component
                const b = (rgb >> 0) & 0xff; // Extract blue component

                // Calculate luminance (perceived brightness)
                const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

                return luminance > 0.5;
              }
              setRelationshipColors(updatedColors);

              if (Array.isArray(classes) && classes.length > 0) {
                return classes.map((classItem, classIndex) => {
                  const isParent = parentClassNames.includes(classItem.name);
                 
                  let longestMethodName = "";
                  classItem.methods.forEach((method) => {
                    if (method.name.length > longestMethodName.length) {
                      longestMethodName = method.name;
                    }
                  });

                  let x, y;
                  if (isParent) {
                    // Set x and y for parent classes
                    x = index + classIndex * 280;
                    y = (index - 1) * 50;
                  } else {
                    // Set x and y for child classes
                    const gap = longestMethodName.length + 100;
                 
                    x = index + -550 + classIndex * (250 + gap);
                    y = index + 1 + classIndex + 1 * 400; // Adjust the y position as needed
                  }

                  return {
                    id: `${classItem.name}`,
                    data: {
                      name: classItem.name,
                      attributes: classItem.attributes || [],
                      methods: classItem.methods || [],
                    },
                    position: { x, y }, //change position later *
                    type: "custom",
                    sourcePosition: "right",
                    targetPosition: "left",
                    className: classItem.name,
                  };
                });
              } else {
                console.error(
                  `No classes found for codeState at index ${index}`
                );
                return [];
              }
            })
            .filter((element) => element !== null);

          setElements(elementsArray || []);
        } else if (tutId) {

          // Initialize an empty array to store extracted information
          const extractedInfo = [];

          // Iterate through each class
          level.classes.forEach((classData) => {
            const className = classData.name;
            const linesOfCode = classData.linesOfCode;
            const classMethods = classData.methods.map((method) => method.name);
            extractedInfo.push({
              class_name: className,
              methods: classMethods,
              lines_of_code: linesOfCode,
            });
          });



          saveLinesOfCode(extractedInfo);

          const relationships = level.relationships;

          //defining inheritence relationship to use and create connections between children and parents class
          inheritanceRelationships = relationships
            .filter(
              (relationship) =>
                relationship.type === "inheritance" &&
                relationship.target &&
                relationship.target.type === "class"
            )
            .map((relationship) => ({
              source: {
                type: relationship.source.type,
                name: relationship.source.name,
              },
              target: {
                type: relationship.target.type,
                name: relationship.target.name,
              },
              type: {
                type: relationship.type,
              },
            }));

          //defining all relationships array and storing source,target and type
          AllRelationships = relationships.map((relationship) => ({
            source: {
              type: relationship.source.type,
              name: relationship.source.name,
            },
            target: {
              type: relationship.target.type,
              name: relationship.target.name,
            },
            type: {
              type: relationship.type,
            },
          }));

          //Creating connection between parent and child class
          newEdges = inheritanceRelationships.map(
            ({ source, target, type }) => ({
              id: `${source.name}-${target.name}`,
              source: `${source.name}`,
              target: `${target.name}`,
              type: "smoothstep",
              animated: {
                duration: 20000, // Increase duration for slower animation
              },
              markerEnd: {
                type: MarkerType.ArrowClosed,
                width: 40,
                height: 40,
                color: "#aba4cf",
              },
              style: {
                stroke: "#beb6e6", // Different color
                strokeWidth: 2, // Thinner stroke width
                strokeDasharray: "20, ", // Increased spacing between dashes
                strokeDashoffset: 0, // Initial offset of the dashes
              },
              arrowHeadType: "arrow",
            })
          );

          setEdges(newEdges);

          // methodOverloadingRelationships.push()
          // projects?.project?.project?.codeStates[projects?.project?.project?.codeStates.length -1].classes

          const updatedColors = {};

          AllRelationships.forEach(({ target, source, type }) => {
            // Generate random colors for source and target
            const randomColor = getRandomColor();

            setAllRelationship(AllRelationship);

            // Identify abstract classes , store them in an array calle dinheritancewithabstract
            // use a variable called usedColors to store the colour of the class
            const abstractClasses = relationships.filter(
              (rel) => rel.type === "abstract class"
            );
            const usedColors = {};

            relationships.forEach((rel) => {
              const randomColor = getRandomColor();

              if (rel.type === "inheritance") {
                isSourceAbstract = abstractClasses.some(
                  (abstract) => abstract.source.name === rel.target.name
                );
                // Set abstract class names and if abstract exists then we add values into our array
                setAbstractClassNames(abstractClassNames);
                if (isSourceAbstract) {
                  inheritanceWithAbstract.push({
                    source: rel.source.name,
                    target: rel.target.name,
                    type: rel.type,
                  });
                  //randomizing the color
                  if (!usedColors[rel.source.name]) {
                    // If color not assigned for this source, assign a random color
                    // Function to generate random color
                    const randomColor = getRandomColor();

                    updatedColors[rel.source.name] = randomColor;
                    usedColors[rel.source.name] = randomColor;
                  } else {
                    updatedColors[rel.source.name] =
                      usedColors[rel.source.name];
                  }
                  updatedColors[rel.target.name] = "grey"; // Assuming 'grey' is the color for abstract classes
                }
              }
            });

            saveDataToRelationshipData({ inheritanceWithAbstract });
            if (type.type === "inheritance") {
              if (!(source.name in updatedColors)) {
                const randomColor = getRandomColor();
                updatedColors[source.name] = randomColor;
              }
              if (!(target.name in updatedColors)) {
                const randomColor = getRandomColor();
                updatedColors[target.name] = randomColor;
              }
              inheritanceWithAbstract.push({
                source: source.name,
                target: target.name,
                type: type.type,
              });
            } else if (type.type === "implements") {
              inheritanceWithAbstract.push({
                source: source.name,
                target: target.name,
                type: target.type,
              });
          

              if (!(source.name in updatedColors)) {
                const randomColor = getRandomColor();
                updatedColors[source.name] = randomColor;
              }
              if (!(target.name in updatedColors)) {
                const randomColor = getRandomColor();
                updatedColors[target.name] = randomColor;
              }
            } else {
              if (!(source.name in updatedColors)) {
                updatedColors[source.name] = randomColor;
              }
              if (!(target.name in updatedColors)) {
                updatedColors[target.name] = randomColor;
              }
            }
          });

          saveDataToRelationshipData({ inheritanceWithAbstract });
          // Store the parent class names
          if (inheritanceWithAbstract.length > 0) {
            for (const relationship of inheritanceWithAbstract) {
              const parentName = relationship.target;
              if (!parentClassNames.includes(parentName)) {
                parentClassNames.push(parentName);
              }
            }
          }


          function getRandomColor() {
            const letters = "89ABCDEF";
            let color = "#";

            do {
              color = "#"; // Reset color
              for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * letters.length)];
              }
            } while (!isLightColor(color));

            return color;
          }

          // Function to check if a color is light
          function isLightColor(color) {
            const hex = color.replace("#", "");
            const rgb = parseInt(hex, 16); // Convert hex to decimal
            const r = (rgb >> 16) & 0xff; // Extract red component
            const g = (rgb >> 8) & 0xff; // Extract green component
            const b = (rgb >> 0) & 0xff; // Extract blue component

            // Calculate luminance (perceived brightness)
            const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

            return luminance > 0.5; // Return true if the color is light
          }

          setRelationshipColors(updatedColors);

          if (Array.isArray(level.classes) && level.classes.length > 0) {
            // Transform the classes data into elements
            const elementsArray = level.classes.flatMap((classItem, index) => {
              // const x = index * 200; // Adjust x position as needed
              // const y = index * 50; // Adjust y position as needed
              const isParent = parentClassNames.includes(classItem.name);
              let longestMethodName = "";
              classItem.methods.forEach((method) => {
                if (method.name.length > longestMethodName.length) {
                  longestMethodName = method.name;
                }
              });

              let x, y;
              if (isParent) {
                // Set x and y for parent classes
                x = index + 450 + index * 280;
                y = index * 50;
              } else {
                // Set x and y for child classes
                const gap = longestMethodName.length * index * index * index;
                x = index + index * 250 + gap;
                y = index + index + 1 * 450; // Adjust the y position as needed
              }

              // let x, y;
              // if (isParent) {
              //   // Set x and y for parent classes
              //   x = (index) + 200 + index * (280);
              //   y = (index) * 50;
              // } else {
              //   // Set x and y for child classes
              //   const gap = longestMethodName.length * 10; // Adjust spacing based on the length of the longest method name
              //   x = (index + 1) * gap; // Adjust the multiplication factor as needed
              //   y = (index + 1) * 50; // Adjust the y position as needed
              // }

              return {
                id: `${classItem.name}`,
                data: {
                  name: classItem.name,
                  attributes: classItem.attributes || [],
                  methods: classItem.methods || [],
                },
                // position: { x, y },
                position: { x, y },
                type: "custom",
                sourcePosition: "right",
                targetPosition: "left",
                className: classItem.name,
              };
            });

            setElements(elementsArray);
          } else {
            console.error("No classes found for tutorial.");
          }
        }
      } catch (error) {
        console.error("Error fetching class names:", error);
      }
    };

    fetchData();
  }, [projId, tutId, proj]);



  useEffect(() => {
    // Update the nodes state when elements change
    if (elements) {
      setNodes(elements);
    }
  }, [elements, setNodes]); // Include setNodes in the dependency array
  //Call this function before drawing a line between two classes and pass the name of the parent and the name of a child
  // The first word of the response should always be "Yes" or "No". The rest of it is the reasoning. if its no we should let them know in a message, 
  //if its yes its nice to have it so that they understand and if for some reason its neither (usually gemini gives its own response anyway but if u want one) 
  //"We don't know what "insert name" and "insert name" represent, so we cannot say whether it makes sense for them to inherit from one another"
  async function checkCodeLogic(childName, parentName) {
    const response = await fetch(
      `api/projects/checkCodeLogic`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${user.user.token}`,
      },
      body: JSON.stringify({ child: childName, parent: parentName }),
    }
    );

    return response;
  }
  const onConnect = useCallback(
    async (params) => {
      const { source, target } = params;

      // Display loading message while waiting for response
      const loadingMessage = message.loading('Checking code logic...', 0);

      try {
        // Call checkCodeLogic function to check if it's valid to create an edge
        const response = await checkCodeLogic(source, target); // Reversed source and target according to the checkCodeLogic function parameters

        const data = await response.json();

        // Close loading message when response is received
        loadingMessage();

        // Check the first word of the response
        const firstWord = data.split(' ')[0];
        if (firstWord.toLowerCase().trim() === 'yes' || firstWord.toLowerCase().trim() ==='yes.') {
          // If the response is 'Yes', proceed with creating the edge
          message.info(data.substring(4), 7);

          // Create the new edge
          const newEdge = {
            ...params,
            type: 'smoothstep',
            animated: {
              duration: 20000, // Increase duration for slower animation
            },
            markerEnd: {
              type: MarkerType.ArrowClosed,
              width: 40,
              height: 40,
              color: '#aba4cf',
            },
            style: {
              stroke: '#beb6e6', // Different color
              strokeWidth: 2, // Thinner stroke width
              strokeDasharray: '20, ', // Increased spacing between dashes
              strokeDashoffset: 0, // Initial offset of the dashes
            },
            arrowHeadType: 'arrow',
          };

          // Add the new edge
          setEdges((eds) => addEdge(newEdge, eds));
          // add the updated code
          everyRelationship.push({
            source: {
              type: "class",
              name: source
            },
            target: {
              type: "class",
              name: target
            },
            type: { type: "inheritance" }
          });

          for (let i = 0; i < codeFiles.length; i++) { //going through each file in the user's project

            const file = codeFiles[i];
            const updatedCode = [];

            for (let j = 0; j < Object.keys(file?.code || {}).length; j++) { //going through each line of code


              let line = file.code[j]; //stores the line of code

              if (proj?.project?.progLang === 'java') { //if its a java project add a java method

                if (line.includes(`class ${source}`)) {
                  if (line.includes("extends")) {
                    message.warning("A class can only inherit from one parent");
                    break;
                  }
                  line = line.replace(line, `public class ${source} extends ${target} {`);
                }



              } else if (proj?.project?.progLang === 'python') { //if its a python project add a python method

                if (line.includes(`class ${source}`)) {
                  line = line.replace(line, `class ${source}(${target}):`);
                }


              }
              updatedCode.push(line);
            }

            codeFiles[i].code = updatedCode;
           
          }
          dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: null });
          dispatchCodeFiles({ type: 'UPDATE_CODE', payload: codeFiles });
          message.info("Inheritance has been added to the code");


        } else if (firstWord.toLowerCase() === 'no'|| firstWord.toLowerCase().trim() === 'no.') {
          // If the response is 'No', display the reasoning but don't create the edge
          message.info(data.substring(3), 7);
        } else {
         
          // If the response is neither 'Yes' nor 'No', display the response message
          message.info(data, 7);
        }
      } catch (error) {
        // Close loading message in case of error
        loadingMessage();
        console.error('Error:', error);
      }
    },
    [setEdges],
  );



  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <ReactFlow
        nodeTypes={nodeTypes}
        elements={elements}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        isAbstract={isSourceAbstract}
        onEdgeUpdate={onEdgeUpdate}
        onEdgeUpdateStart={onEdgeUpdateStart}
        onEdgeUpdateEnd={onEdgeUpdateEnd}
      >
        <Background />
        <Controls />
        <MiniMap />
      </ReactFlow>
    </div>
  );
};

export default AnimationDatabase;
