import { createContext, useReducer, useContext } from "react";

export const CodeFilesContext = createContext();
export const codeFileReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_CODE":
      return {
        ...state,
        codeFiles: action.payload,
      };
    case "UPDATE_FILE_SELECTED":
      return {
        ...state,
        selectedFile: action.payload,
      };
    case "ACE_EDITOR_REF":
      return {
        ...state,
        editorRef: action.payload,
      }; 
    // case "DELETED_COMPONENT":
    //   return {
    //     ...state,
    //     deletedComponent: action.payload,
    //   };
    // case "DELETED_COMPONENT_CODE":
    //   return {
    //     ...state,
    //     deletedComponentCode: action.payload,
    //   };
    default:
      return state;
  }
};

export const CodeFilesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(codeFileReducer, {
    codeFiles: null,
    selectedFile: null,
    editorRef: null
    // deletedComponent: null,
    // deletedComponentCode: null,
  });

  return (
    <CodeFilesContext.Provider value={{ state, dispatch }}>
      {children}
    </CodeFilesContext.Provider>
  );
};
