import styles from "../pages/Projects.module.css";

import { useState, useCallback, useEffect, dispatch } from "react";
const Container = ({ project,
    onclick
  })=>{
    
  // Function to get a random linear gradient from the provided array
  function getRandomGradient() {
    const gradients = [
      'linear-gradient(92.09deg, rgb(65 128 204) 36.98%, rgb(5 72 155))',
      'linear-gradient(92.09deg, rgb(160 91 53) 36.98%, rgb(190, 81, 65))',
      'linear-gradient(96.5deg, rgb(165 87 143) 36.98%, rgb(122 55 103))',
      'linear-gradient(101.29deg, rgb(101 86 162) 36.98%, rgb(119, 90, 180))',
      'linear-gradient(96.5deg, rgb(192 153 77) 36.98%, rgb(175 120 10))',
      'linear-gradient(92.09deg, rgb(107 138 188) 36.98%, rgb(67 95 139))',
      'linear-gradient(96.5deg, rgb(90 148 142) 36.98%, rgb(64 141 133))',
      'linear-gradient(92.09deg, rgb(59 136 136) 36.98%, rgb(18, 117, 117))'
    ];
    const randomIndex = Math.floor(Math.random() * gradients.length);
    return gradients[randomIndex];
  }
  

  return (
    <>
            <div className={styles.recentProjectProject1} onClick={onclick}>
            <div className={styles.recentProjectProject1Child}   style={{ background:  getRandomGradient() }} />
            <div className={styles.p1}>{project.prjName.slice(0,1).toUpperCase()+ "1"}</div>
        </div>

    </>
  );
};

export default Container;
