import { useMemo } from "react";
import styles from "./TutorialStep.module.css";
import { useTutorialsContext } from "../context/TutorialsContext";
// ... (your imports and other code)

const TutorialStep = ({
  oopConceptTitles,
  conceptTitle,
  conceptDescription,
  conceptNumber,
  conceptCode,
  conceptCodeImageUrls,
  carMake,
  conceptCodeDimensions,
  propTop = "66px",
  propLeft = "15px",
  propRight = "51.74%",
  propLeft1 = "46.86%",
  propRight1 = "48.66%",
  propLeft2 = "49.95%",
  onNext, // Add onNext as a prop
  onPrev, // Add onPrev as a prop
  onNextBig,
  onPrevBig,
  isEditMinusClicked,
  isEditPlusClicked,
  onImplement,
  background,
  top,
  lefto,
}) => {
  const step1Style = useMemo(() => {
    return {
      left: propLeft,
    };
  }, [propLeft]);

  const step2Style = useMemo(() => {
    return {
      top: top,
      left: lefto,
    };
  }, [top, lefto]);
  // const vectorIconStyle = useMemo(() => {
  //   return {
  //     right: propRight,
  //     left: propLeft1,
  //   };
  // }, [propRight, propLeft1]);

  // const vectorIcon1Style = useMemo(() => {
  //   return {
  //     right: propRight1,
  //     left: propLeft2,

  //   };
  // }, [propRight1, propLeft2]);
  const {isObjectifyingTutorial:isObjectifyingTutorial, tutorial: tutorial}=useTutorialsContext();

  return (
    <div className={styles.step1} style={{ ...step1Style, "--oop-concept-background": background }}>
      <div className={styles.content}>
        <img
          className={styles.vectorIcon}
          alt=""
          src={conceptTitle}
          onClick={onPrev} // Call onPrev when the icon is clicked
        />
        <img
          className={styles.vectorIcon1}
          alt=""
          src={conceptDescription}
          onClick={onNext} // Call onNext when the icon is clicked
        />
        {!isEditMinusClicked && !isEditPlusClicked && !isObjectifyingTutorial && !tutorial &&(
          <>
            <div className={styles.identifiedOopConcept} style={{ left: '5%' }}>{"Identified OOP Concept "}     </div>

            <div className={styles.identifiedOopConceptTitle}>
              {oopConceptTitles} 

              <div className={styles.vectorIconContainer}>
                <img
                  className={styles.vectorIcon2}
                  alt=""
                  src={conceptCode}
                  onClick={onPrevBig} // Call onPrev when the icon is clicked
                />
                <img
                  className={styles.vectorIcon3}
                  alt=""
                  src={conceptCodeImageUrls}
                  onClick={onNextBig} // Call onNext when the icon is clicked
                />
              </div>
            </div>
          </>
        )}
        {(isEditMinusClicked || isEditPlusClicked) && (
          <>
            <div className={styles.identifiedOopConcept} style={{ left: '5%' }}>{isEditMinusClicked ? "Recommended Deletion " : "Recommended Addition "}</div>
            <div className={styles.identifiedOopConceptTitle}> {oopConceptTitles} </div>
            <button className={styles.implement} onClick={onImplement}>
              <img style={{ height: '13px', width: '13px' }} src={process.env.PUBLIC_URL + "/icons8-sparkle.png"} /> {isEditMinusClicked ? "Implement" : "Implement"}
            </button>
          </>
        )}
        {!isEditMinusClicked && !isEditPlusClicked && tutorial && (
          <>
            <div className={styles.identifiedOopConcept} style={{ left: '5%' }}>{"Tutorial Steps"}</div>
            <div className={styles.identifiedOopConceptTitle}> {oopConceptTitles} </div>
          </>
        )}
        {!isEditMinusClicked && !isEditPlusClicked && !tutorial && !isObjectifyingTutorial && <div className={styles.div2}>{conceptNumber}</div>}
        <div className={styles.theFirstClass}>{carMake}</div>
        <div className={styles.div} style={{ ...step2Style }} >{conceptCodeDimensions}</div>

      </div>
    </div>
  );
};

export default TutorialStep;
