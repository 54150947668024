
import { useState, useCallback, useContext, useEffect } from "react";
import styles from "./choiceForLevelsPopUp.module.css";

import { message } from "antd";
import PortalPopup from "./PortalPopup";

const ChoiceForLevelsPopUp = ({ onClose , options, handleLanguageSelection }) => { // Capitalize the function name
  const [isVisible, setIsVisible] = useState(true); // Use useState inside a React functional component
  
  const [isChoiceForLevelsPopUpOpen, setChoiceForLevelsPopUpOpen] =
    useState(false);

  const openChoiceForLevelsPopUp = useCallback(() => {
    setChoiceForLevelsPopUpOpen(true);
  }, []);

  const closeChoiceForLevelsPopUp = useCallback(() => {
    setChoiceForLevelsPopUpOpen(false);
  }, []);

  const handleClick = (language) => {
    if (options.includes(language)) {
      handleLanguageSelection(language);
      setIsVisible(false);
    } else {
        message.info("Enroll in the language " + language + " to get started!");
      }
    onClose();
  };

  return (
    <div className={styles.popup} style={{ display: isVisible ? "block" : "none" }}>
      <div className={styles.popupContent}>
      <div className={styles.frameforcross}>
        <img
          className={styles.pagecrossIcon}
          alt=""
          src="/pagecross31.svg"
          onClick={onClose}
        />
      </div>
        <div className={styles.text}> <h2>Select Language</h2> </div>
        <div >
        <button
            className={`${styles.languageButtons} ${!options.includes('Java') ? styles.disabled : ''}`}
            onClick={() => handleClick("Java")}
          >
            Java
          </button>
          <button
            className={`${styles.languageButtons} ${!options.includes('Python') ? styles.disabled : ''}`}
            onClick={() => handleClick("Python")}
          >
            Python
          </button>
          <button
            className={`${styles.languageButtons} ${!options.includes('C++') ? styles.disabled : ''}`}
            onClick={() => handleClick("C++")}
          >
            C++
          </button>
        </div>
      </div>

      

    </div>
  );
};

export default ChoiceForLevelsPopUp; // Corrected the component name to start with a capital letter
