//CLASS
import React, { useEffect, useState, useRef } from 'react';
import { Handle } from 'react-flow-renderer';
import Method from './Method';
import Attribute from "./Attribute"
import { withTheme } from '@emotion/react';
import './Animation.css';
import { message } from 'antd';
import { setRelationshipColors, getRelationshipColor } from './relationshipColors';
import { getParentClassMethods, saveParentClassMethods } from './parentClassData';
import { getInheritanceAbstractRelationships } from './relationshipData';
import { useCodeFilesContext } from '../hooks/useCodeFilesContext';
import { useProjectsContext } from "../hooks/useProjectsContext.js";
const Class = ({ everyRelationship, data, isConnectable, methodOverridingRelationships, onRename, addClass, deleteClass }) => {
  const inheritanceWithAbstract = getInheritanceAbstractRelationships();
  useEffect(() => {
    // Side effects or logic based on everyRelationship
  }, [everyRelationship]);


  let methodArray = [];
  let attributeArray = [];
  // const { state: { codeFiles: codeFiles } } = useCodeFilesContext();
  const {
    state: { codeFiles: codeFiles, editorRef: editorRef },
  } = useCodeFilesContext();
  const { dispatch: dispatchCodeFiles } = useCodeFilesContext();

  const projects = useProjectsContext();
  const newClassNameRef = useRef(data.name); //to store the prev value
  const [newClassName, setNewClassName] = useState(data.name);
  const tabspace=' '.repeat(5);
  const indentTabSpace=' '.repeat(9);


  function getRandomColor() {
    const letters = '89ABCDEF';
    let color = '#';

    do {
      color = '#'; // Reset color
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
    } while (!isLightColor(color));

    return color;
  }

  // Function to check if a color is light
  function isLightColor(color) {
    const hex = color.replace('#', '');
    const rgb = parseInt(hex, 16); // Convert hex to decimal
    const r = (rgb >> 16) & 0xff; // Extract red component
    const g = (rgb >> 8) & 0xff; // Extract green component
    const b = (rgb >> 0) & 0xff; // Extract blue component

    // Calculate luminance (perceived brightness)
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    return luminance > 0.5;
  }

  let color1;
  color1 = getRelationshipColor(data.name)
  if (color1 === "defaultColor") {
    // If the color is the default color, generate a random color
    color1 = getRandomColor();
    let updatedColors = [];
    updatedColors[data.name] = color1;
    setRelationshipColors(updatedColors);

  }

  const boxStyle = {
    '--box-border-color': getRelationshipColor(data.name),
    '--box-background-color': getRelationshipColor(data.name),
    // ... other styles
  };

  const isAbstract = inheritanceWithAbstract.some(rel => rel.target === data.name)


  const classBoxy = {
    backgroundColor: getRelationshipColor(data.name),
    color: 'black',
  }

  //will make the gray at the end into a black box
  const BOX = {
  };

  const [methodcChildren, setMethodChildren] = useState(data.methods ? data.methods.map(method => ({ id: method._id, name: method.name })) : []);

  const [attributeChildren, setAttributeChildren] = useState(data.attributes ? data.attributes.map((attribute) => ({ name: attribute.name, id: attribute._id, access_modifier: attribute.access_modifier })) : []);
  const [isEditing, setIsEditing] = useState(false);
  // -------------------Methods---------------------
  const [addMethodCounter, setAddMethodCounter] = useState(1);
  const handleDeleteMethodChild = (childId, name) => {
    const updatedChildren = methodcChildren.filter((method) => method.id !== childId);
    setMethodChildren(updatedChildren);

    // Implement logic to delete the selected element (class, method, or attribute)

    // Example: Retrieve foldable lines from Ace Editor
    const session = editorRef.current.editor.getSession();
    const foldableLines = [];
    var beforeContent = false;
    const beforeConentLines = [0, 0, ''];
    for (let i = 0; i < session.getLength(); i++) {
      const foldRange = session.getFoldWidgetRange(i);
      if (foldRange) {
        var startRow = foldRange.start.row; // Start row of the foldable range
        var endRow = foldRange.end.row; // End row of the foldable range

        // Get the content within the foldable range
        var content = session.getLines(startRow, endRow).join("\n");
        var contentFirstLine = session.getLine(startRow);
        //here if content has a line that has class and name

        const regexPatternClass = new RegExp(`^(public\\s+)?(class|interface|abstract)\\s+${data.name}\\s*(\\([^\\)]*\\))?\\s*:?\\s*`);

        var contentForDelete = [];
        if (beforeConentLines[2].indexOf("interface") !== -1 || beforeConentLines[2].indexOf("obstract") !== -1) {
          const length = codeFiles.length;
          for (let i = 0; i < length; i++) {
            //goes through number of code files
            const file = codeFiles[i];
            var updatedCodev1 = [];
            // var lineToDelete = 0;
            // var foundDeletedClass = false;

            for (let j = 0; j < file.code.length; j++) {
              var trimmedline = file.code[j].trim();
              var line = file.code[j];
              if (trimmedline.indexOf(name) !== -1) {
                continue;
              } else {
                updatedCodev1.push(line);
                continue;
              }
            }
            codeFiles[i].code = updatedCodev1;
        }
        dispatchCodeFiles({ type: "UPDATE_FILE_SELECTED", payload: null });
          dispatchCodeFiles({ type: "UPDATE_CODE", payload: codeFiles });
      } else if (beforeConentLines[0] <= startRow && beforeConentLines[1] >= endRow) {
        if (contentFirstLine.indexOf(name) != -1) {

          var methodStartRow = foldRange.start.row;
          var methodEndRow = foldRange.end.row;
          contentForDelete = content.split("\n");

          //remove it from codeFiles
          const length = codeFiles.length;
          for (let i = 0; i < length; i++) {
            //goes through number of code files
            const file = codeFiles[i];
            const updatedCode = [];
            var lineToDelete = 0;
            var foundDeletedClass = false;

            for (let j = 0; j < file.code.length; j++) {
              //check if the line is the same as the contentForDelete[0]
              const line = file.code[j];
              if (j > beforeConentLines[0] && j < beforeConentLines[1]) {
                if (line.trim() === contentForDelete[0].trim()) {
                  foundDeletedClass = true;
                }
                if (foundDeletedClass) {
                  lineToDelete++;
                  if (lineToDelete <= contentForDelete.length) {
                    continue;
                  } else {
                    updatedCode.push(line);
                    continue;
                  }
                }
              }
              updatedCode.push(line);
            }
            codeFiles[i].code = updatedCode;
          }
          dispatchCodeFiles({ type: "UPDATE_FILE_SELECTED", payload: null });
          dispatchCodeFiles({ type: "UPDATE_CODE", payload: codeFiles });
        }
      }
      if (regexPatternClass.test(contentFirstLine)) {
        beforeContent = true
        beforeConentLines[0] = foldRange.start.row;
        beforeConentLines[1] = foldRange.end.row;
        beforeConentLines[2] = session.getLines(startRow, endRow).join("\n");
      } else beforeContent = false;
      // Add the foldable range to the foldableLines array if needed
      foldableLines.push({ start: startRow + 1, end: endRow + 1 });
    }

  }
  message.info("Method has been deleted from the code", 3);
};
  const handleAddMethodChild = () => {
    let newMethodName = `newMethod${data.name}${addMethodCounter}`;
    const newMethodId = `method${data.name}${addMethodCounter}`;

    let counter = addMethodCounter;
    while (methodcChildren.some(method => method.name === newMethodName)) {
      counter++;
      newMethodName = `newMethod${data.name}${counter}`;
    }
    const newMethod = {
      id: newMethodId,
      name: newMethodName, // Default name for the new method
      //access_modifier: "public", // Default access modifier for the new method
      parameters: [],

    };
    setMethodChildren([...methodcChildren, newMethod]);
    setAddMethodCounter(counter + 1);
    let newMethodCode = '';
    const length = codeFiles.length;

    for (let i = 0; i < length; i++) { //going through each file in the user's project

      const file = codeFiles[i];
      const updatedCode = [];
      let push = false;

      for (let j = 0; j < file.code.length; j++) { //going through each line of code

        const line = file.code[j]; //stores the line of code
        let newMethodCode = '';
        if (projects?.project?.project?.progLang === 'java') { //if its a java project add a java method
          newMethodCode = `public void ${newMethod.name}()\t{\n\t\t// Enter code here\n\t}`;
          everyRelationship.forEach(item => {
            // Check if source.name equals the current class name and type.type is "abstract class"
            if (item.source.name === data.name && item.type.type === 'interface') {
              // If the conditions are met, find all the children of this class
              everyRelationship.forEach(childItem => {
                if (childItem.type.type === 'interface' && childItem.source.name === item.source.name) {
                  // Filter for the target.name where the type is 'interface' and the source name matches
                  // add the same new method code line in the child also but before it write @Override
                }
              });
            }

          });
        } else if (projects?.project?.project?.progLang === 'python') { //if its a python project add a python method
          newMethodCode = `def ${newMethod.name}():\n${indentTabSpace}pass # Add code here\n`;
        }

        updatedCode.push(line);

        // Find the line containing the declaration of the class 
        const words = line.split(/\s+/); // Split the line into words using whitespace as delimiter
        var classIndex = words.indexOf('class'); // Find the index of 'class' in the array
        if (classIndex==-1){
          classIndex=words.indexOf('interface');
        }
        // Check if 'class' is present and if there's a word after it
        if (classIndex !== -1 && classIndex < words.length - 1) {
          const nextWord = words[classIndex + 1].replace(/[^\w]+$/, '').split("(")[0];
          if (nextWord === data.name && !push) {
            // 'data.name' is the word after 'class'
            // Insert the new method code right after the declaration of the class 
            updatedCode.push(`\n\t${newMethodCode}\n`);
            push = true;
          }
        }

      }

      codeFiles[i].code = updatedCode;


      message.info("New method has been added to the code", 3);

    }
    dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: null });
    dispatchCodeFiles({ type: 'UPDATE_CODE', payload: codeFiles });



  };
  // ----------------Attributes-----------------
  var [addAttributeCounter, setAddAttributeCounter] = useState(1);

  const handleAddAttributeChild = () => {

    let newAttributeName = `newAttribute${data.name}${addAttributeCounter}`;
    const newAttributeId = `attribute${data.name}${addAttributeCounter}`;
    let counter = addAttributeCounter;
    while (attributeChildren.some(attribute => attribute.name === newAttributeName)) {
      counter++;
      newAttributeName = `newAttribute${data.name}${counter}`;
    }
    const newAttribute = {
      id: newAttributeId,
      name: newAttributeName, // Default name for the new attribute
      access_modifier: "public", // Default access modifier for the new attribute
      type: "String", // Default type for the new attribute
    };
    setAttributeChildren([...attributeChildren, newAttribute]);
    setAddAttributeCounter(counter + 1);     // addAttributeCounter.current=addAttributeCounter.current+1;

    // Adding to code
    const length = codeFiles.length;
    let newAttributeCode = '';

    for (let i = 0; i < length; i++) { // Goes through number of code files
      let foundPythonClass = false;
      const file = codeFiles[i]; // Going through each file in the user's project
      let currentClass = '';
      // Check for progLang first
      if (projects?.project?.project?.progLang === 'java') { // If it's a Java project, add a Java attribute
        newAttributeCode = `\n\tpublic ${newAttribute.type} ${newAttribute.name};`;
      } else if (projects?.project?.project?.progLang === 'python') { // If it's Python, add it to the def _init_ parameters
        newAttributeCode = `, ${newAttribute.name}):\n${indentTabSpace}self.${newAttribute.name}=${newAttribute.name}`;
      }

      const updatedCode = [];
      let pushed = false;
      for (let j = 0; j < file.code.length; j++) { // Going through each line of code for each file
        const line = file.code[j];

        // Find the line containing the declaration of the main method
        if (projects?.project?.project?.progLang === 'java') {
          updatedCode.push(line);
          if (line.includes(data.name) && !pushed) {
            // Insert the new attribute code right after the class declaration for Java code 
            updatedCode.push(`${newAttributeCode}\n`);
            pushed = true;
          }
        } else if (projects?.project?.project?.progLang === 'python') {

          //step 1: Store the class name
          if (line.includes("class") && !line.includes("#")) {
            // Split the line by whitespace
            const parts = line.split(/\s+/);
            // Find the index of the "class" keyword
            const classIndex = parts.findIndex(part => part === "class");
            // Extract the class name which should be the next word after "class"
            if (classIndex !== -1 && classIndex < parts.length - 1) {
              let className = parts[classIndex + 1];
              // If there are any special characters or symbols attached to the class name, remove them
              const indexOfParentheses = className.indexOf('(');
              if (indexOfParentheses !== -1) {
                className = className.substring(0, indexOfParentheses);
              }
              currentClass = className;
              currentClass = currentClass.replace(/[^\w\s]*$/, "");
            }
          }

          if (currentClass !== data.name && foundPythonClass && !pushed) { //this means that the class was found but no init function was found

            //Here we would need to add the attributes of the parent inside the innit function 
            //I check to find the currentClass inside the everythingRelationships array here
            let parentClass = null;
            // Iterate over everyRelationship array
            everyRelationship.forEach(relationship => {
              // Check if the relationship is of type inheritance and the source matches data.name
              if (relationship.type.type === 'inheritance' && relationship.source.name === data.name) {
                // Store the target class name in the parentClass variable
                parentClass = relationship.target.name;
                // Stop iterating once a matching relationship is found
                return;
              }
            });

            // Now parentClass will hold the name of the parent class, if found
            let parentAttributes = "";

            //Now I need to get all the attributes of the parent
            if (parentClass !== null) {


              // Filter out the object from the `classes` array where `name` matches `parentClass`
              const parentClassObject = projects?.project?.project?.codeStates[projects?.project?.project?.codeStates.length - 1].classes.find(cls => cls.name === parentClass);

              // Check if the parent class object exists
              if (parentClassObject) {
                // If the parent class object exists, store its attributes in the `parentAttributes` variable
                parentClassObject.attributes.map((attr) =>
                  parentAttributes += attr.name + ","
                );
              }

              // Now `parentAttributes` will hold the attributes of the parent class, if found
            }
            //map through everyRelationship, filter out inheritance and check if the source===data.name
            const initFunction = `\tdef __init__(self, ${parentAttributes}${newAttributeCode.slice(1)}\n`
            updatedCode.push(initFunction);
            pushed = true;
          }
          //step 2: Check if the line has data.name 
          if (currentClass === data.name && !pushed) {
            foundPythonClass = true; //if the class is data.name then we found the correct class to add it to
          }
          //
          if (!pushed && foundPythonClass) { //if the found class is correct aand the attribute hasn't been pushed then add it in 2 cases

            //case 1: the init function exists
            if (line.includes('_init_')) {
              const updatedLine = line.replace('):', newAttributeCode);
              updatedCode.push(updatedLine);
              pushed = true;
              continue;
            }

          }
          updatedCode.push(line);

        }
      }
      codeFiles[i].code = updatedCode;

      // If newAttributeCode was not added, add it after the class definition for Python
      message.info("New attribute has been added to the code.", 3);
    }

    dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: null });
    dispatchCodeFiles({ type: 'UPDATE_CODE', payload: codeFiles });
  };


  const handleDeleteAttributeChild = (childIdToDelete, name) => {
    const updatedChildren = attributeChildren.filter((child) => child.id !== childIdToDelete.id);
    setAttributeChildren(updatedChildren);
  
  
    // -------------------------------------------
  
  
      // Implement logic to delete the selected element (class, method, or attribute)
  
      // Example: Retrieve foldable lines from Ace Editor
      const session = editorRef.current.editor.getSession();
      const foldableLines = [];
      var beforeContent = false;
      const beforeConentLines = [0, 0, ''];
      for (let i = 0; i < session.getLength(); i++) {
        const foldRange = session.getFoldWidgetRange(i);
        if (foldRange) {
          var startRow = foldRange.start.row; // Start row of the foldable range
          var endRow = foldRange.end.row; // End row of the foldable range
  
          // Get the content within the foldable range
          var content = session.getLines(startRow, endRow).join("\n");
          var contentFirstLine = session.getLine(startRow);
          //here if content has a line that has class and name
      
          const regexPatternClass = new RegExp(`^(public\\s+)?(class|interface|abstract)\\s+${data.name}\\s*(\\([^\\)]*\\))?\\s*:?\\s*`);
  
          var contentForDelete = [];
          if (( beforeConentLines[0] <= startRow && beforeConentLines[1] >= endRow)) {
            const length = codeFiles.length;
            for (let i = 0; i < length; i++) {
              //goes through number of code files
              const file = codeFiles[i];
              var updatedCodev1 = [];
          
              for (let j = 0; j < file.code.length; j++) {
                var trimmedline = file.code[j].trim();
                var line = file.code[j];
                if (trimmedline.indexOf(name) !== -1) {
  
                  continue;
                } else {
                  updatedCodev1.push(line);
                  continue;
                }
              }
              codeFiles[i].code = updatedCodev1;
          }
          dispatchCodeFiles({ type: "UPDATE_FILE_SELECTED", payload: null });
            dispatchCodeFiles({ type: "UPDATE_CODE", payload: codeFiles });
        } 
  
        if (regexPatternClass.test(contentFirstLine)) {
          beforeContent = true
          beforeConentLines[0] = foldRange.start.row;
          beforeConentLines[1] = foldRange.end.row;
          beforeConentLines[2] = session.getLines(startRow, endRow).join("\n");
         
        } else beforeContent = false;
        // Add the foldable range to the foldableLines array if needed
        foldableLines.push({ start: startRow + 1, end: endRow + 1 });
      }
  
    }
    message.info("Method has been deleted from the code", 3);
  
  };
  

  // ----------------Change Class Name ---------
  const handleClassNameChange = (event) => {
    setNewClassName(event.target.value);
  };

  const handleRename = () => {
    setIsEditing(false);
    const length = codeFiles.length;
    for (let i = 0; i < length; i++) { //goes through number of code files

      const file = codeFiles[i];
      const updatedCode = [];
      for (let j = 0; j < file.code.length; j++) {
        const line = file.code[j];
        const updatedLine = line.replace(new RegExp('\\b' + newClassNameRef.current + '\\b', 'g'), newClassName);

        updatedCode.push(updatedLine);
      }
      // Update the code of the current file with the updated code
      codeFiles[i].code = updatedCode;
    }
    message.info("Class has been renamed in the code")
    // Dispatch an action to update the code files state
    dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: null });
    dispatchCodeFiles({ type: 'UPDATE_CODE', payload: codeFiles });


    // // Dispatch an action to update the code files state
    newClassNameRef.current = newClassName;

  };


  const mergeStyles = (style1, style2) => {
    return { ...style1, ...style2 };

  };

  let currMethod = '';



  const formatOverloadedMethod = (prog, methodName) => {
    if (prog === "java" || prog === "cpp") {
      let overLoadingRelationship = '';
      let formattedMethods = [];
      let methodOverloadingRelationships = inheritanceWithAbstract.filter(relationship => relationship.type === 'method overloading');
      for (let i = 0; i < methodOverloadingRelationships.length; i++) {
        const relationship = methodOverloadingRelationships[i];
        const sourceParts = relationship.source.name.split("."); // Classname.methodname
        const targetParts = relationship.target.name.split(".");

        if (data.name === sourceParts[0] && methodName === sourceParts[1].split("(")[0] && sourceParts[1].split("(")[0] === targetParts[1].split("(")[0]) {
          var parameters = '';
          if (currMethod !== sourceParts[1]) {
            parameters = sourceParts[1]
              .match(/\('([^']+)', '([^']+)'\), \('([^']+)', '([^']+)'\)/)
              .slice(1);
            currMethod = sourceParts[1];
          } else {
            parameters = targetParts[1]
              .match(/\('([^']+)', '([^']+)'\), \('([^']+)', '([^']+)'\)/)
              .slice(1);
            currMethod = targetParts[1];
          }

          // Formatting the parameters
          const formattedParameters = [];
          for (let j = 0; j < parameters.length; j += 2) {
            const [name, type] = parameters.slice(j, j + 2);
            formattedParameters.push(`${type} ${name}`);
          }

          // Constructing the final formatted string
          const formattedString = `${sourceParts[1].split("(")[0]}(${formattedParameters.join(', ')})`;
          let isPresent = false;

          for (let k = 0; k < formattedMethods.length; k++) {
            if (formattedMethods[k] === formattedString) {
              isPresent = true;
              break; // No need to continue searching if the item is found
            }
          }
          if (isPresent == true) {
            continue;
          }
          overLoadingRelationship = formattedString;
          formattedMethods.push(overLoadingRelationship);

        }


        if (overLoadingRelationship.length > 0) {
        } else {
          overLoadingRelationship = methodName; // Return the original methodName if no relationships were found
        }
      }
      return overLoadingRelationship || methodName; // Return either the formatted string or the original methodName
    } else {
      return methodName; // Return the original methodName if the programming language is not supported
    }
  };
  //Defining method relationships and their colours


  let target;
  let classname;
  let colorForClass;
  inheritanceWithAbstract.forEach((relationship, index) => {

    // Find all target classes that are also source classes
    const targetAsSource = [];
    inheritanceWithAbstract.forEach(relationship => {
      if (inheritanceWithAbstract.some(r => r.source === relationship.target)) {
        targetAsSource.push(relationship.target);
        targetAsSource.push(relationship.source);
      }
    });

    // Loop through all relationships and treat them accordingly
    inheritanceWithAbstract.forEach(relationship => {
      if (targetAsSource.includes(relationship.target)) {
        if (targetAsSource[0] === 0) {
          // Set target to the first value during the first iteration
          target = relationship.target;
          classname = target; // Example class name
          colorForClass = getRelationshipColor(classname);
          const parentClassName = target;
          let parentClass = data.name === parentClassName ? data : null;
          if (parentClass) {
            // Iterate over parent class methods
            parentClass.methods.forEach(method => {
              // Check if the method already exists in methodArray
              methodArray.push({ name: method.name, color: getRelationshipColor(data.name) });
            });
            saveParentClassMethods(methodArray);
          }

        } else {
          // Set target to the second value during subsequent iterations
          target = relationship.target;
          methodArray = getParentClassMethods();

          classname = target; // Example class name
          colorForClass = getRelationshipColor(classname);
          const parentClassName = target;
          let parentClass = data.name === parentClassName ? data : null;
          if (parentClass) {
            // Iterate over parent class methods
            parentClass.methods.forEach(method => {
              // Check if the method already exists in methodArray
              const existingMethodIndex = methodArray.findIndex(item => item.name === method.name);
              if (existingMethodIndex === -1) {
                // If the method doesn't exist, add it to methodArray with its color
                methodArray.push({ name: method.name, color: getRelationshipColor(data.name) });
              }
            })
            saveParentClassMethods(methodArray);


          }
        }
        // Treat target as a separate instance
        // Logic to populate methodArray for this instance
      } else {
        target = relationship.target;
        classname = target; // Example class name
        colorForClass = getRelationshipColor(classname);

        const parentClassName = target;
        let parentClass = data.name === parentClassName ? data : null;
        if (parentClass) {
          // Iterate over parent class methods
          parentClass.methods.forEach(method => {
            // Check if the method already exists in methodArray
            methodArray.push({ name: method.name, color: getRelationshipColor(data.name) });
          });

          saveParentClassMethods(methodArray);
        }
        // Treat both instances of target separately
        // Logic to populate methodArray for each instance
      }
    });
  });



  const getAttributeStyle = (attributeName) => {


    //gettign cass clolour for the others
    const calssnamee = data.name; // Example class name
    const color = getRelationshipColor(calssnamee);

    // If the method name is "hasAccess", apply a black style; otherwise, apply pink
    const style = {
      background: color,
      position: 'relative',
      boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.25)',
      backdropFilter: 'blur(7.5px)',
      padding: '7px 40px 7px 40px',
      borderRadius: '9px',
      borderImage: 'linear-gradient(to bottom, #a3d4a3, #fff) 1',
      margin: '3px 5px',
      fontFamily: 'var(--font-poppins)',
      color: 'black',
      ".lockIcon": {
        height: '2px', // Example height value
        width: '2px',  // Example width value
      }
    };

    return {
      style: style,
    };
  };



  const getMethodStyle = (methodName, dataName) => {
    const formattedMethodName = methodName;

    let isHasAccessMethod;
    let colorForMethod;
    const parentClassMethods = getParentClassMethods(data, methodName);

    // Iterate over the parent class methods array
    for (const methodObj of parentClassMethods) {
      if (isHasAccessMethod && methodObj.name === methodName) {
        colorForMethod = methodObj.color;
        isHasAccessMethod = true;
        break;
        // Now you can use colorForMethod for further processing
      }

      else if (methodObj.name === methodName) {
        // Method exists in parent class methods
        // Check if the class name exists in inheritanceWithAbstract array
        if (!inheritanceWithAbstract.some(item => item.source === data.name || item.target === data.name)) {
          // If not, assign color based on class name
          colorForMethod = getRelationshipColor(data.name);
        } else {
          colorForMethod = methodObj.color;
          isHasAccessMethod = true;
          break;
        }

      }

    }



    if (!colorForMethod) {

      // Method does not exist in parent class methods
      isHasAccessMethod = false;
  
    }

    //gettign cass clolour for the others
    const calssnamee = data.name; // Example class name
    let color = getRelationshipColor(calssnamee);
    if (color === "default color") {
      // If the color is the default color, generate a random color
      color = color1;
    }



    // If the method name is "hasAccess", apply a black style; otherwise, apply pink
    const style = isHasAccessMethod ? {
      position: 'relative',
      boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.25)',
      backdropFilter: 'blur(7.5px)',
      padding: '7px 40px 7px 40px',
      borderRadius: '9px',
      borderImage: 'linear-gradient(to bottom, #a3d4a3, #fff) 1',
      margin: '3px 5px',
      fontFamily: 'var(--font-poppins)',
      background: colorForMethod,
      color: 'black',
    } : {
      background: color,
      position: 'relative',
      boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.25)',
      backdropFilter: 'blur(7.5px)',
      padding: '7px 40px 7px 40px',
      borderRadius: '9px',
      borderImage: 'linear-gradient(to bottom, #a3d4a3, #fff) 1',
      margin: '3px 5px',
      fontFamily: 'var(--font-poppins)',
      color: 'black',
    };


    return {
      style: style,
      displayMethodName: formattedMethodName,
    };
  };

  // Calculate the height of the attribute container based on the number of attributes
  const attributeContainerHeight = data.attributes ? `${data.attributes.length * 80}px` : 'auto';

  // Calculate the height of the method container based on the number of methods
  const methodContainerHeight = data.methods ? `${data.methods.length * 80}px` : 'auto';


  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className="boxStyle" style={BOX}>

        {isEditing ? (<>
          <input
            type="text"
            value={newClassName}
            onChange={handleClassNameChange}
            onBlur={handleRename}
            autoFocus
            className="inputStyleForClass"
          /><br /></>
        ) : (
          <p className="classBoxParent" style={classBoxy} onClick={() => setIsEditing(true)}>
            {newClassName}<span onClick={() => deleteClass(data.name)} className='deleteClass'>-</span>
          </p>
        )}
        <span className="addButton" onClick={() => handleAddAttributeChild()}>+</span>
        <strong style={{ marginLeft: "8px" }}>Attributes:</strong> <br />





        {attributeChildren.length > 0 && (
          <>
            <div className="attributeScroll" style={{ '--custom-variable': getRelationshipColor(data.name) }}>
              {attributeChildren.map((attribute) => {
                const { style } = getAttributeStyle(attribute.name);
                const isEncapsulated = attribute.access_modifier === 'private';
                return (
                  <Attribute
                    id={attribute.id}
                    key={attribute.id}
                    attributeName={attribute.name}
                    style={style}
                    isEncapsulated={isEncapsulated}
                    onDelete={() => handleDeleteAttributeChild(attribute, attribute.name)
                    } />
                );
              })}

            </div>
          </>
        )}


        <span className="addButton" onClick={() => handleAddMethodChild()}>+</span>
        <strong style={{ marginLeft: "8px" }}>Method:</strong> <br />

        {methodcChildren.length > 0 && (
          <>
            <div className="methodScroll" style={{ '--custom-variable': getRelationshipColor(data.name) }} >
              {methodcChildren.map((method) => {
                const { style, displayMethodName } = getMethodStyle(method.name, data.name);

                return (
                  <Method
                    key={method.id} // Using the method's id as the key
                    methodName={method.name}
                    onDelete={() => handleDeleteMethodChild(method.id, method.name)} // Pass the method's id to the handler
                    style={style}
                    id={method.id} // Pass the method's id as a prop to the Method component
                    parent={data.name}
                  />
                );
              })}
            </div>
          </>
        )}
        <Handle type="source" position="top" isConnectable={isConnectable} />
        <Handle type="target" position="bottom" isConnectable={isConnectable} /><div className="sideButtons" style={{ position: 'absolute', top: '40%', left: '-90px', transform: 'translateY(-50%)', width: 'auto', height: 'auto', borderRadius: '8px', overflow: 'hidden' }} onClick={() => addClass(data.name, 0)}>
          <div style={{ display: 'flex', backgroundColor: 'black', color: 'white', padding: '5px 10px', borderRadius: '8px 8px 0 0' }}>
            <span style={{ fontFamily: 'var(--font-poppins)', fontSize: '18px', flex: '1', textAlign: 'center' }}>Class</span>
          </div>
          <div style={{ display: 'flex', backgroundColor: 'white', padding: '5px 10px', borderRadius: '0 0 8px 8px', cursor: 'pointer' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>
              <span>&#9608;&#9608;</span>

            </div>
          </div>
        </div>

        <div className="sideButtons" style={{ position: 'absolute', top: '40%', right: '-90px', transform: 'translateY(-50%)', width: 'auto', height: 'auto', borderRadius: '8px', overflow: 'hidden' }} onClick={() => addClass(data.name, 0)}>
          <div style={{ display: 'flex', backgroundColor: 'black', color: 'white', padding: '5px 10px', borderRadius: '8px 8px 0 0' }}>
            <span style={{ fontFamily: 'var(--font-poppins)', fontSize: '18px', flex: '1', textAlign: 'center' }}>Class</span>
          </div>
          <div style={{ display: 'flex', backgroundColor: 'white', padding: '5px 10px', borderRadius: '0 0 8px 8px', cursor: 'pointer' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>

              <span>&#9608;&#9608;</span>
            </div>
          </div>
        </div>

        <div className="sideButtons" style={{ position: 'absolute', top: '-135px', left: '58%', transform: 'translateX(-50%)', width: 'auto', height: 'auto', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          {!everyRelationship?.some(relationship => relationship.source.name === data.name && (relationship.type.type === "abstract class" || relationship.type.type === "inheritance")) && (
            <div style={{ marginRight: '20px', marginBottom: '15px', borderRadius: '8px', overflow: 'hidden' }} onClick={() => addClass(data.name, 3)}>
              <div style={{ display: 'flex', backgroundColor: '#603FEF', color: 'white', padding: '5px 10px', borderRadius: '8px 8px 0 0' }}>
                <span style={{ fontFamily: 'var(--font-poppins)', fontSize: '18px', flex: '1', textAlign: 'center' }}>Parent</span>
              </div>
              <div style={{ display: 'flex', backgroundColor: 'white', padding: '5px 10px', borderRadius: '0 0 8px 8px', cursor: 'pointer' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>
                  <span>&#9608;&#9608;</span>
                </div>
              </div>
            </div>
          )}
          {!everyRelationship?.some(relationship => relationship.source.name === data.name && (relationship.type.type === "abstract class" || relationship.type.type === "inheritance")) && (
            <div style={{ marginRight: '20px', borderRadius: '8px', overflow: 'hidden' }} onClick={() => addClass(data.name, 1)}>
              <div style={{ display: 'flex', backgroundColor: 'grey', color: 'white', padding: '5px 10px', borderRadius: '8px 8px 0 0' }}>
                <span style={{ fontFamily: 'var(--font-poppins)', fontSize: '18px', flex: '1', textAlign: 'center' }}>Abstract</span>
              </div>
              <div style={{ display: 'flex', backgroundColor: 'white', padding: '5px 10px', borderRadius: '0 0 8px 8px', cursor: 'pointer' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>
                  <span>&#9608;&#9608;</span>
                  <span>&#11014;</span>
                </div>
              </div>
            </div>
          )}
          {projects?.project?.project?.progLang !== 'python' && (
            <div style={{ marginRight: '20px', marginBottom: '15px', borderRadius: '8px', overflow: 'hidden' }}>
              <div style={{ display: 'flex', backgroundColor: '#008080', color: 'white', padding: '5px 10px', borderRadius: '8px 8px 0 0' }}>
                <span style={{ fontFamily: 'var(--font-poppins)', fontSize: '18px', flex: '1', textAlign: 'center' }}>Interface</span>
              </div>
              <div style={{ display: 'flex', backgroundColor: 'white', padding: '5px 10px', borderRadius: '0 0 8px 8px', cursor: 'pointer' }} onClick={() => addClass(data.name, 2)}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>
                  <span>&#9608;&#9608;</span>
                </div>
              </div>
            </div>
          )}

        </div>

        <div className="sideButtons" style={{ position: 'absolute', bottom: '-120px', left: '50%', transform: 'translateX(-50%)', width: '100px', height: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '8px', overflow: 'hidden' }} onClick={() => addClass(data.name, 4)}>
          <div style={{ display: 'flex', backgroundColor: '#006ca5', color: 'white', padding: '5px 10px', borderRadius: '8px 8px 0 0' }}>
            <span style={{ fontFamily: 'var(--font-poppins)', fontSize: '18px', flex: '1', textAlign: 'center' }}>Child</span>
          </div>
          <div style={{ display: 'flex', backgroundColor: 'white', padding: '5px 10px', borderRadius: '0 0 8px 8px', cursor: 'pointer' }}>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: '1' }}>

              <span>&#9608;&#9608;&#9608;</span>
            </div>
          </div>
        </div>
      </div>


    </div>

  );


}
export default Class;
