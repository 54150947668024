import styles from "./DashbordCardJavaRP.module.css";
import { useMemo } from "react";
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

import { useState, useCallback, useEffect, dispatch } from "react";
import { useNavigate } from "react-router-dom";

const DashbordCardJavaRP = ({project, index}) => {

  const navigate = useNavigate();
  const onFrameButtonClick = useCallback(() => {
    navigate("/CodeEditor", { state: { ProjectId: project._id } });
  }, [navigate, project._id]);
  
  const [randomGradient, setRandomGradient] = useState(getRandomGradient());

  // Function to get a random linear gradient from the provided array
  function getRandomGradient() {
    const gradients = [
      'linear-gradient(92.09deg, rgb(65 128 204) 36.98%, rgb(5 72 155))',
      'linear-gradient(92.09deg, rgb(160 91 53) 36.98%, rgb(190, 81, 65))',
      'linear-gradient(96.5deg, rgb(165 87 143) 36.98%, rgb(122 55 103))',
      'linear-gradient(101.29deg, rgb(101 86 162) 36.98%, rgb(119, 90, 180))',
      'linear-gradient(96.5deg, rgb(192 153 77) 36.98%, rgb(175 120 10))',
      'linear-gradient(92.09deg, rgb(107 138 188) 36.98%, rgb(67 95 139))',
      'linear-gradient(96.5deg, rgb(90 148 142) 36.98%, rgb(64 141 133))',
      'linear-gradient(92.09deg, rgb(59 136 136) 36.98%, rgb(18, 117, 117))'
    ];
    const randomIndex = Math.floor(Math.random() * gradients.length);
    return gradients[randomIndex];
  }
  
  // Update the random gradient when the component mounts
  useEffect(() => {
    setRandomGradient(getRandomGradient());
  }, []);

  return (
  <div className={styles.javaRecentprojects} style={{ left: `${index === 0 ? 280 : 560 + (index - 1) * 280}px` }}>

      <div className={styles.javaRecentprojectsChild} style={{ background: randomGradient }} onClick={onFrameButtonClick} />
      {/* <img
        className={styles.javaRecentprojectsItem}
        alt=""
        src="/frame-33618@2x.png"
      /> */}
      <div className={styles.javaRecentprojectsInner} >
        <div className={styles.javaWrapper}>
          <div className={styles.java}>{project.progLang}</div>
        </div>
      </div>
      <div className={styles.animalsLab5}>{project.prjName}</div>
      <div className={styles.lastUpdated7}>Updated {formatDistanceToNow(new Date(project.updatedAt), {addSuffix: true})}</div>
    </div>
  );
};

export default DashbordCardJavaRP;
