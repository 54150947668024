// relationshipColors.js

const relationshipColors = {};

export const setRelationshipColors = (colors) => {
  Object.assign(relationshipColors, colors);
};

export const getRelationshipColor = (className) => {
  return relationshipColors[className] || 'defaultColor';
};
