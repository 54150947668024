import React, { useState, useRef } from 'react';
import Xarrow, { useXarrow, Xwrapper } from 'react-xarrows';
import Draggable from 'react-draggable';
import { useCodeFilesContext } from '../hooks/useCodeFilesContext';
import {message} from 'antd';

const Attribute = ({ id, attributeName, onDelete, style, onRename,isEncapsulated }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [newAttributeName, setnewAttributeName] = useState(attributeName);
  const newAttributeNameRef = useRef(attributeName); //to store the prev value
  const { state: { codeFiles, selectedFile }, dispatch: dispatchCodeFiles } = useCodeFilesContext();

  // const [newMethod, setnewMethod] = useState(attributeName);

  const handleAttributeNameChange = (event) => {
    setnewAttributeName(event.target.value);
  };


  const handleRename = () => {
    setIsEditing(false);

    const length = codeFiles.length;
    for (let i = 0; i < length; i++) { //goes through number of code files

      const file = codeFiles[i];
      const updatedCode = [];
      for (let j = 0; j < file.code.length; j++) {
        const line = file.code[j];
        const updatedLine = line.replace(new RegExp('\\b' + newAttributeNameRef.current + '\\b', 'g'), newAttributeName); //newAttributeNameRef.current hold the value of the last changed variable

        updatedCode.push(updatedLine);
      }
      // Update the code of the current file with the updated code
      codeFiles[i].code = updatedCode;
    }
message.info("Attribute has been renamed in the code")
    // Dispatch an action to update the code files state
    dispatchCodeFiles({ type: 'UPDATE_FILE_SELECTED', payload: null });

    dispatchCodeFiles({ type: 'UPDATE_CODE', payload: codeFiles });


    // // Dispatch an action to update the code files state
    // codeFiles.dispatch({ type: 'UPDATE_CODE', payload: { codeFiles: updatedCodeFiles } });
    newAttributeNameRef.current = newAttributeName;

  };

  return (
    <div id={id} style={style}>
      {isEditing ? (
        <input
          type="text"
          value={newAttributeName}
          onChange={handleAttributeNameChange}
          onBlur={handleRename}
          autoFocus
          className="inputStyle"
        />
      ) : (
        <>
      <span
  onClick={onDelete}
  style={{
    cursor: 'pointer',
    color: 'black', // Initial color
    transition: 'color 0.1s', // Smooth transition effect
  }}
  onMouseEnter={(e) => { e.target.style.color = 'red'; }} // Change color on hover
  onMouseLeave={(e) => { e.target.style.color = 'black'; }} // Restore initial color
>
&#128465;&nbsp;
</span>
 
          <span onClick={() => setIsEditing(true)}>
          {newAttributeName}           {isEncapsulated && <img  style={{ height: '20px', width: '20px'}} alt="Lock" src={process.env.PUBLIC_URL + "/lock.svg"} />

}
          </span>         
        </>
      )}
    </div>
  );
  
};

export default Attribute;
