import { createContext, useReducer } from 'react';

export const ProjectsContext = createContext();

export const projectsReducer = (state, action) => {
  switch (action.type) {
    case 'GET_PROJECTS':
      return {
        ...state,
        projects: action.payload
      };
    case 'GET_PROJECT':
      return {
        ...state,
        project: action.payload
      };
    case 'DELETE_PROJECT':
      return {
        ...state,
        project: action.payload
      };
    case 'CREATE_PROJECT':
      return {
        ...state,
        project: action.payload
      };
    case 'UPDATE_PROJECT':
      return {
        ...state,
        project: { ...state.project, ...action.payload },
      };
    case 'SET_OBJECTIFY_STATE_PROJECT':
      return {
        ...state,
        isObjectifyingProject: action.payload,
      };
    case 'GET_RECOMMENDATIONS':
      return {
        ...state,
        recommendations: action.payload
      };
    case 'FETCH_PROJECTS_START':
      return {
        ...state,
        isFetchingProjects: true,
        fetchProjectsError: null
      };
    case 'FETCH_PROJECTS_SUCCESS':
      return {
        ...state,
        isFetchingProjects: false,
        fetchProjectsError: null
      };
    case 'FETCH_PROJECTS_FAILURE':
      return {
        ...state,
        isFetchingProjects: false,
        fetchProjectsError: action.payload
      };
    default:
      return state;
  }
};

export const ProjectsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(projectsReducer, {
    projects: null,
    project: null,
    isObjectifyingProject: false,
    recommendations: null,
    isFetchingProjects: false,
    fetchProjectsError: null
  });

  const updateProject = (updatedCode) => {
    dispatch({ type: 'UPDATE_PROJECT', payload: updatedCode });
  };

  return (
    <ProjectsContext.Provider value={{ ...state, dispatch, updateProject, isObjectifyingProject: state.isObjectifyingProject }}>
      {children}
    </ProjectsContext.Provider>
  );
};
