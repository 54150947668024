// tutorialColors.js
const tutorialColors = {
    "65af373b0dee0644571cd68e": "linear-gradient(92.09deg, rgb(65 128 204) 36.98%, rgb(5 72 155)) ",
    "658cde676d2f767bcdab2f1d": "linear-gradient(92.09deg, rgb(160 91 53) 36.98%, rgb(190, 81, 65))",
    "65af3af740ffceaae9181f1b": "linear-gradient(96.5deg, rgb(165 87 143) 36.98%, rgb(122 55 103))",
    "658cde686d2f767bcdab2f51": "linear-gradient(101.29deg, rgb(101 86 162) 36.98%, rgb(119, 90, 180))",
    "658cde686d2f767bcdab2f49":"linear-gradient(96.5deg, rgb(192 153 77) 36.98%, rgb(175 120 10))",
    "658cde676d2f767bcdab2f3e":"linear-gradient(92.09deg, rgb(107 138 188) 36.98%, rgb(67 95 139))",
    "658cde676d2f767bcdab2f33":"linear-gradient(96.5deg, rgb(90 148 142) 36.98%, rgb(64 141 133))",
    "658cde686d2f767bcdab2f5c":"linear-gradient(92.09deg, rgb(59 136 136) 36.98%, rgb(18, 117, 117))",

};

  // const hoverColors = {
  //   "65af373b0dee0644571cd68e": "rgb(5 72 155, 0.3)",
  //   "658cde676d2f767bcdab2f1d": "rgba(190, 81, 65, 108, 0.3)",
  //   "65af3af740ffceaae9181f1b": "rgba(165, 60, 158, 108, 0.3)",
  //   "658cde686d2f767bcdab2f51":"rgba(136, 104, 204, 0.3)",
  //   "658cde686d2f767bcdab2f49":"rgb(192 153 77, 0.3)",
  //   "658cde676d2f767bcdab2f3e":"rgb(67 95 139, 0.3)",
  //   "658cde676d2f767bcdab2f33":"rgb(90 148 142, 0.3)",
  //   "658cde686d2f767bcdab2f5c":"rgba(48, 150, 108, 0.3)",
  // };
  
  const hoverColors = {
    "65af373b0dee0644571cd68e": "rgb(5, 72, 155, 0.5)",
    "658cde676d2f767bcdab2f1d": "rgba(190, 81, 65, 0.5)",
    "65af3af740ffceaae9181f1b": "rgba(165, 60, 158, 0.5)",
    "658cde686d2f767bcdab2f51": "rgba(136, 104, 204, 0.5)",
    "658cde686d2f767bcdab2f49": "rgb(192, 153, 77, 0.5)",
    "658cde676d2f767bcdab2f3e": "rgb(67, 95, 139, 0.5)",
    "658cde676d2f767bcdab2f33": "rgb(90, 148, 142, 0.5)",
    "658cde686d2f767bcdab2f5c": "rgba(48, 150, 108, 0.5)",
  };
  
  export { tutorialColors, hoverColors };
  